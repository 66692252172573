
import { IonList, IonItem, IonLabel, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, InfiniteScrollCustomEvent, IonAvatar, useIonRouter } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api, Recording } from "@/api/api";
import { play, heart } from "ionicons/icons";
import { Prop, Watch } from "vue-property-decorator";
import { lockClosed } from "ionicons/icons";

@Options({
  name: "Recordings",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonAvatar,
  },
})
export default class Recordings extends Vue {
  @Prop({ type: String })
  public hall!: string;

  @Prop({ type: String })
  public search!: string;

  @Prop({ type: Number })
  public user!: number;

  @Prop({ type: Boolean })
  public includePrivate!: boolean;

  @Prop({ type: Number })
  public titleId!: number;

  @Prop({ type: Number })
  public artistId!: number;

  @Prop({ type: String })
  public joinInstruments!: string;

  @Prop({ type: Number })
  public notUser!: number;

  @Prop({ type: Number })
  public ensembleSubId!: number;

  @Prop({ type: Number })
  public updateCnt!: number;

  @Prop({ type: Boolean })
  public isReplace!: boolean;

  ionRouter = useIonRouter();

  api = api;
  play = play;
  heart = heart;
  lockClosed = lockClosed;
  recordings: Recording[] = [];
  limit = 25;
  offset = 0;

  mounted() {
    this.load();
  }

  @Watch("hall")
  onHallChanged() {
    this.load();
  }

  @Watch("search")
  onChangeSearch() {
    this.load();
  }

  @Watch("titleId")
  onChangeTitleId() {
    this.load();
  }

  @Watch("artistId")
  onChangeAritstId() {
    this.load();
  }

  @Watch("joinInstruments")
  onChangeJoinInstruments() {
    this.load();
  }

  @Watch("notUser")
  onChangeNotUser() {
    this.load();
  }

  @Watch("ensembleSudIb")
  onChangeEnsembleSubId() {
    this.load();
  }

  @Watch("updateCnt")
  onChangeUpdateCnt() {
    this.load();
  }

  load(isNew = true) {
    if (isNew) {
      this.limit = 25;
      this.offset = 0;
    }
    const params: { [id: string]: any } = {
      limit: this.limit,
      offset: this.offset,
    };
    if (this.hall) {
      params["hall"] = this.hall;
    }
    if (this.search) {
      params["search"] = this.search;
    }
    if (this.user) {
      params["user"] = this.user;
    }
    if (this.includePrivate) {
      params["includePrivate"] = this.includePrivate;
    }
    if (this.titleId) {
      params["titleId"] = this.titleId;
    }
    if (this.artistId) {
      params["artistId"] = this.artistId;
    }
    if (this.joinInstruments) {
      params["joinInstruments"] = this.joinInstruments;
    }
    if (this.notUser) {
      params["notUser"] = this.notUser;
    }
    if (this.ensembleSubId) {
      params["ensembleSubId"] = this.ensembleSubId;
    }
    api.get(`/apps/recordings`, params).then((r) => {
      if (r && r.data) {
        if (isNew) {
          this.recordings = r.data["recordings"];
          this.offset += this.limit;
        } else {
          this.recordings = [...this.recordings, ...r.data["recordings"]];
          this.offset += this.limit;
        }
        for (const recording of this.recordings) {
          if (recording.users) {
            recording.duration = Math.max(...recording.users.map((u) => u.length), 0);
            // console.log(recording.duration, recording.users);
          }
        }
      }
    });
  }

  onView(ensembleId: number) {
    // if (this.isReplace) {
    //   this.ionRouter.replace(`/ensembles/${ensembleId}/${this.includePrivate}`);
    // } else {
    this.ionRouter.push(`/ensembles/${ensembleId}/${this.includePrivate}/true`);
    // }
  }

  onInfiniteScroll(ev: InfiniteScrollCustomEvent) {
    this.load(false);
    setTimeout(() => ev.target.complete(), 500);
  }

  goToUser(userId: number) {
    if (userId === api.user.userId) {
      // if (this.isReplace) {
      this.ionRouter.replace(`/tabs/my`);
      // } else {
      //   this.ionRouter.push(`/tabs/my`);
      // }
    } else {
      // if (this.isReplace) {
      //   this.ionRouter.replace(`/user/${userId}`);
      // } else {
      this.ionRouter.push(`/user/${userId}`);
      // }
    }
  }
}
