import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c57e1e66"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "line"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "line group" }
const _hoisted_5 = { class: "line-col" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "line-col" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "line-col" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "line" }
const _hoisted_12 = ["src"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  slot: "end",
  style: {"margin-inline-end":"-5px","text-align":"center"}
}
const _hoisted_15 = {
  key: 0,
  class: "no-content"
}
const _hoisted_16 = {
  key: 1,
  class: "no-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recordings, (r) => {
        return (_openBlock(), _createBlock(_component_ion_item, {
          key: r.recordingId,
          class: "ion-no-padding"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_thumbnail, {
              slot: "start",
              onClick: ($event: any) => (r.parts == 1 ? _ctx.onView(r.ensembleId) : _ctx.onDetail(r.recordingId, r.title, r.artist))
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  alt: "Silhouette of mountains",
                  src: _ctx.api.getAssetUrl(r.poster)
                }, null, 8, _hoisted_1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createElementVNode("div", null, [
              (_ctx.user)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(r.title), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "line",
                onClick: ($event: any) => (r.parts == 1 ? _ctx.onView(r.ensembleId) : _ctx.onDetail(r.recordingId, r.title, r.artist))
              }, _toDisplayString(_ctx.getInstrumentsString(r.ensemble)), 9, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("img", {
                    src: `${_ctx.api.publicPath}/assets/images/ico-heart.svg`,
                    class: ""
                  }, null, 8, _hoisted_6),
                  _createElementVNode("span", null, _toDisplayString(r.likes), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: `${_ctx.api.publicPath}/assets/images/ico-play-sm.svg`,
                    class: ""
                  }, null, 8, _hoisted_8),
                  _createElementVNode("span", null, _toDisplayString(r.visits), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_ion_avatar, { class: "avatar" }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: r.avatar ? _ctx.api.getAssetUrl(r.avatar) : `${_ctx.api.publicPath}/assets/images/ico-avatar.svg`
                      }, null, 8, _hoisted_10)
                    ]),
                    _: 2
                  }, 1024),
                  _createElementVNode("span", null, _toDisplayString(r.users.length), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_ion_avatar, {
                  class: "avatar",
                  onClick: ($event: any) => (_ctx.goToUser(r.userId))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: r.avatar ? _ctx.api.getAssetUrl(r.avatar) : `${_ctx.api.publicPath}/assets/images/ico-avatar.svg`
                    }, null, 8, _hoisted_12)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createElementVNode("span", {
                  onClick: ($event: any) => (_ctx.goToUser(r.userId))
                }, _toDisplayString(r.starter), 9, _hoisted_13),
                _createTextVNode(" played " + _toDisplayString(r.instrument), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              (r.parts > 1)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    onClick: ($event: any) => (_ctx.onJoin(r.productId, r.ensembleId, r.title, r.artist))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Join")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 2
        }, 1024))
      }), 128)),
      (_ctx.recordings.length == 0)
        ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
            default: _withCtx(() => [
              (_ctx.user === undefined)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode("There is no recordings.")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Be the first player!")
                      ]),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode("There is no recordings.")
                      ]),
                      _: 1
                    })
                  ]))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_infinite_scroll, {
        onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInfiniteScroll($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_infinite_scroll_content)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}