import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17c2fe1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, { class: "list" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.songs, (s) => {
        return (_openBlock(), _createBlock(_component_ion_item, {
          key: s,
          class: "ion-no-padding item",
          lines: "none"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_thumbnail, {
              slot: "start",
              onClick: ($event: any) => (_ctx.onStarted(s.titleId, s.artistId, s.title, s.artist))
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.api.getAssetUrl(s.image)
                }, null, 8, _hoisted_1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_ion_label, {
              style: {"margin":"auto"},
              onClick: ($event: any) => (_ctx.onStarted(s.titleId, s.artistId, s.title, s.artist))
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", _hoisted_2, _toDisplayString(s.title), 1),
                _createVNode(_component_ion_note, { class: "artist" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(s.artist), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024))
      }), 128)),
      _createVNode(_component_ion_infinite_scroll, {
        onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInfiniteScroll($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_infinite_scroll_content)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}