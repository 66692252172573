/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { Solui } from "solui";

const EXPIRE_COOKIE = "60d";

class Api {
  api: AxiosInstance;
  user: UserToken = {
    userId: null,
    email: null,
    instruments: null,
  };
  instruments: { [id: string]: string } = {};
  publicPath = window.location.href.indexOf("localhost") != -1 ? "" : "/app";
  needUpdate = false;
  playerWidth = 390;
  playerHeight = 195;
  // nativeOpenCnt = 0;
  isStart = true;

  constructor() {
    console.log(process.env.NODE_ENV, process.env.PACKING);
    let baseURL = "https://api.solchestra.com";
    if (process.env.NODE_ENV === "development" ) {
      baseURL = "http://localhost:8000";
    } else if (window.location.href.indexOf("belokan") != -1) {
      baseURL = "https://api.belokan.net";
    }
    this.api = axios.create({
      baseURL: baseURL,
      withCredentials: true,
    });
    this.checkMe(() => {
      console.log("me checked!");
    });
    this.get("/instruments").then((r) => {
      if (r && r.data) {
        const insts: Instrument[] = r.data["instruments"];
        this.instruments["none"] = "None";
        for (const inst of insts) {
          this.instruments[inst.instrumentCode] = inst.name;
        }
        console.log(this.instruments);
      }
    });
    this.playerWidth = Math.min(window.innerWidth, window.innerHeight);
    this.playerHeight = Math.min(this.playerWidth / 2, 215);
    // console.log("player", this.playerWidth, this.playerHeight);
  }

  setAuth(value: string): void {
    cookies.set("auth", value, EXPIRE_COOKIE);
  }

  resetAuth(): void {
    cookies.remove("auth");
    this.user.userId = null;
    this.user.email = null;
    this.user.instruments = null;
  }

  configureAuth(value: string, config?: any): any {
    if (config) {
      if (config["headers"]) {
        config["headers"]["Authorization"] = value;
      } else {
        config["headers"] = {
          Authorization: value,
        };
      }
    } else {
      config = {
        headers: {
          Authorization: value,
        },
      };
    }
    return config;
  }

  checkMe(callback: () => void): void {
    // this.resetAuth();
    const auth = cookies.get("auth");
    if (auth) {
      this.get("/token/me")
        .then((r) => {
          if (r && r.data) {
            this.user = r.data;
            if (this.user.userId !== r.data["userId"]) {
              this.user.userId = r.data["userId"];
            }
            if (this.user.email !== r.data["email"]) {
              this.user.email = r.data["email"];
            }
            if (this.user.instruments !== r.data["instruments"]) {
              this.user.instruments = r.data["instruments"];
            }
            console.log(this.user);
          } else {
            this.user.userId = null;
            this.user.email = null;
            this.user.instruments = null;
          }
          callback();
        })
        .catch((e) => {
          this.user.userId = null;
          this.user.email = null;
          this.user.instruments = null;
          console.log(e);
          callback();
        });
    } else {
      this.user.userId = null;
      this.user.email = null;
      this.user.instruments = null;
      callback();
    }
    console.log(this.user);
  }

  getAssetUrl(assetId: string, isVideo = false): string {
    if (process.env.NODE_ENV === "development") {
      return `${this.api.defaults.baseURL}/assets/${assetId}`;
    } else if (window.location.href.indexOf("belokan") != -1) {
      if (isVideo) {
        return `/assets/${assetId}.mp4`;
      }
      return `/assets/${assetId}`;
    }
    if (isVideo) {
      return `https://solchestra-assets.s3.ap-northeast-2.amazonaws.com/assets/${assetId}.mp4`;
    }
    return `https://solchestra-assets.s3.ap-northeast-2.amazonaws.com/assets/${assetId}`;
  }

  getDuration(sec: number): string {
    const minute = Math.floor(sec / 60);
    const second = Math.floor(sec - minute * 60);
    return minute + ":" + ("00" + second).slice(-2);
  }

  getParameter(dict?: any): string {
    if (dict) {
      let param = "";
      let isFirst = true;
      for (const k in dict) {
        if (isFirst) {
          param += `${k}=${dict[k]}`;
        } else {
          param += `&${k}=${dict[k]}`;
        }
        isFirst = false;
      }
      return `?${param}`;
    }
    return "";
  }

  get(url: string, params?: any, config?: any): Promise<AxiosResponse<any, any>> {
    url = `${url}${this.getParameter(params)}`;
    const auth = cookies.get("auth");
    if (auth) {
      config = this.configureAuth(auth, config);
    }
    return this.api.get(url, config);
  }

  post(url: string, data?: any, params?: any, config?: any): Promise<AxiosResponse<any, any>> {
    url = `${url}${this.getParameter(params)}`;
    const auth = cookies.get("auth");
    if (auth) {
      config = this.configureAuth(auth, config);
    }
    return this.api.post(url, data, config);
  }

  put(url: string, data?: any, params?: any, config?: any): Promise<AxiosResponse<any, any>> {
    url = `${url}${this.getParameter(params)}`;
    const auth = cookies.get("auth");
    if (auth) {
      config = this.configureAuth(auth, config);
    }
    return this.api.put(url, data, config);
  }

  delete(url: string, params?: any, config?: any): Promise<AxiosResponse<any, any>> {
    url = `${url}${this.getParameter(params)}`;
    const auth = cookies.get("auth");
    if (auth) {
      config = this.configureAuth(auth, config);
    }
    return this.api.delete(url, config);
  }

  async openNativePlayer(ensembleId: number, left: number, top: number, width: number, height: number, autoplay: boolean, landscape = false, ignore = 0) {
    // if (this.nativeOpenCnt < 0) {
    //   this.nativeOpenCnt = 0;
    //   console.log("prohibit native player because aleady closed!");
    //   return;
    // }
    const result = (
      await Solui.openPlayer({
        ensembleId: `${ensembleId}`,
        rect: {
          left: left,
          top: top,
          width: width,
          height: height,
        },
        autoplay: autoplay,
        landscape: landscape,
        ignore: ignore,
      })
    ).json;
    console.log("openNativePlayer: ", result);
    // this.nativeOpenCnt += 1;
  }

  async closeNativePlayer(ensembleId: number) {
    // this.nativeOpenCnt -= 1;
    console.log("closeNative: ");
    const result = (
      await Solui.closePlayer({
        ensembleId: `${ensembleId}`,
      })
    ).json;
    console.log("closeNativePlayer: ", result);
  }

  async commandNativePlayer(command: string) {
    console.log("commandNative: ");
    const result = (
      await Solui.commandPlayer({
        command: command,
      })
    ).json;
    console.log("commandNativePlayer: ", result);
  }

  async openNativeRecorder(left: number, top: number, width: number, height: number, degree: number): Promise<string> {
    const result = (
      await Solui.openRecorder({
        rect: {
          left: left,
          top: top,
          width: width,
          height: height,
        },
        degree: degree,
      })
    ).json;
    console.log("openNativeRecorder: ", result);
    return result;
  }

  async commandNativeRecorder(command: string) {
    const result = (
      await Solui.commandRecorder({
        command: command,
      })
    ).json;
    console.log("commandNativeRecorder: ", result);
  }

  async closeNativeRecorder(): Promise<string> {
    const result = (
      await Solui.closeRecorder()
    ).json;
    console.log("closeNativeRecorder: ", result);
    return result;
  }

  async restartNativeRecorder(): Promise<string> {
    const result = (
      await Solui.restartRecorder()
    ).json;
    console.log("restartNativeRecorder: ", result);
    return result;
  }

  async rotateNativeRecorder(left: number, top: number, width: number, height: number, degree: number): Promise<string> {
    const result = (
      await Solui.rotateRecorder({
        rect: {
          left: left,
          top: top,
          width: width,
          height: height,
        },
        degree: degree,
      })
    ).json;
    console.log("rotateNativeRecorder: ", result);
    return result;
  }

  async checkPermission(): Promise<boolean> {
    const result = (
      await Solui.checkPermission()
    ).result;
    console.log("checkPermission: ", result);
    return result;
  }

  async requestPermission() {
    const result = (
      await Solui.requestPermission()
    ).json;
    console.log("requestPermission: ", result);
  }

  async getVersion(): Promise<string> {
    const result = (
      await Solui.getVersion()
    ).result;
    console.log("getVersion: ", result);
    return result;
  }

  async setCaffeine(isCaffeine: boolean) {
    await Solui.setCaffeine({
      isCaffeine: isCaffeine
    });
    console.log("caffeine", isCaffeine)
  }

  async shareKakao(description: string, image: string, url: string): Promise<string> {
    const result = (
      await Solui.shareKakao({
        description: description,
        image: image,
        url: url
      })
    ).json;
    console.log("shareKakao: ", result);
    return result;
  }

  async isWiredHeadphone(): Promise<boolean> {
    const result = (await Solui.isWiredHeadphone()).result;
    console.log("isWiredHeadphone", result);
    return result;
  }

  async isExternalMic(): Promise<boolean> {
    const result = (await Solui.isExternalMic()).result;
    console.log("isExternalMic", result);
    return result;
  }
}

export const api = new Api();

export interface UserToken {
  userId: number | null;
  email: string | null;
  instruments: string | null;
}

export interface Song {
  titleId: number;
  title: string;
  artistId: number;
  artist: string;
  image: string;
  count: number;
  joinable: number;
}

export interface Category {
  main: string;
  subId: string;
  sub: string;
  productId?: number;
}

export interface CategorySub {
  subId: string;
  name: string;
  productId?: number;
}

export interface RecordingUser {
  userId: number;
  image: string;
  nickname: string;
  instrument: string;
  recordingId: number;
  assetId: string;
  type: string;
  sync: number;
  volume: number;
  poster: string;
  length: number;
  no: number;
}

export interface Recording {
  title: string;
  artist: string;
  main: string;
  sub: string;
  likes: number;
  visits: number;
  productId: number;
  ensembleId: number;
  users: RecordingUser[];
  isPublic: string;
  isCompleted: string;
  tempo: number;
  duration: number;
  parts: number;
  joinable: number;
}

export interface SearchRank {
  term: string;
  count: number;
}

interface Instrument {
  instrumentCode: string;
  name: string;
}

export interface User {
  userId: number;
  nickName: string;
  image?: string;
  description: string;
  instrument: string;
  preferedGenre: string;
}

export interface Article {
  articleId: number;
  boardName: string;
  nickName: string;
  title: string;
  status: string;
  registerDate: string;
}

export interface ArticleDetail {
  articleId?: number;
  boardName?: string;
  boardId: number;
  nickName?: string;
  email?: string;
  status: string;
  title: string;
  content: string;
  visitCount?: number;
  registerDate?: string;
  attachments?: string;
}

export interface Asset {
  assetId: string;
  ctype: string;
  filename: string;
}

export interface BannerDetail {
  bannerId: number;
  category: number;
  name: string;
  status: string;
  allDate: string;
  startDate: string;
  endDate: string;
  link: string;
  bannerType: number;
  text: string;
  image: string;
}

export interface StartedRecording {
  ensembleId: number,
  productId: number,
  recordingId: number,
  userId: number,
  parts: number,
  poster: string,
  ensemble: string,
  likes: number,
  visits: number,
  instrument: string,
  joinable: number,
  titleId: number,
  artistId: number,
  title: string,
  artist: string,
  users: RecordingUser[];
  starter: string,
  avatar: string
}

export interface StartedRecordingDetailEnsemble {
  ensembleId: number;
  joinable: number;
  likes: number;
  visits: number;
  productId: number;
  title: string;
  artist: string;
  users: RecordingUser[];
}
export interface StartedRecordingDetail {
  userId: number;
  nickname: string;
  instrument: string;
  image: string;
  parts: string
  poster: string;
  type: string;
  video: string;
  visits: number;
  likes: number;
  length: number;
  productId: number;
  ensembles: StartedRecordingDetailEnsemble[];
}