
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonButton, IonList, IonItem, IonLabel, IonInput, useIonRouter, alertController } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";

@Options({
  name: "Forgot",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
  },
})
export default class Forgot extends Vue {
  api = api;
  ionRouter = useIonRouter();
  email = "";

  onContinue() {
    console.log(this.email);
    api.post("/apps/reset", null, { email: this.email }).then((r) => {
      if (r && r.data && r.data["status"] == "success") {
        this.alert("Thanks, we've sent you an email with a new password. If you haven't received the email, please try again or check your spam folder.", true);
      } else {
        this.alert("Not Found!, Please check your email!");
      }
    });
  }

  onCancel() {
    this.$router.back();
  }

  async alert(msg: string, isBack = false) {
    const alert = await alertController.create({
      cssClass: "custom-alert",
      header: "Alert",
      message: msg,
      buttons: [
        {
          text: "OK",
          cssClass: "alert-button-confirm",
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
    await alert.onDidDismiss();
    if (isBack) {
      this.$router.back();
    }
  }
}
