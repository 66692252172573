
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonButton, IonList, IonItem, IonLabel, IonInput, IonCheckbox, IonSelect, IonSelectOption, useIonRouter, alertController } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";

@Options({
  name: "NewUser",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
  },
})
export default class NewUser extends Vue {
  api = api;
  ionRouter = useIonRouter();
  step = 1;
  name = "";
  surname = "";
  nickname = "";
  email = "";
  isEmail = false;
  passwd = "";
  repasswd = "";
  userId = 0;
  instrument: string | null = null;
  genre: string[] | null = null;
  genres: { [id: string]: string } = {};
  nickOk = false;
  nickChecked = false;
  emailOk = false;
  emailChecked = false;

  ionViewWillEnter() {
    this.step = 1;
    this.name = "";
    this.surname = "";
    this.nickname = "";
    this.email = "";
    this.isEmail = false;
    this.passwd = "";
    this.repasswd = "";
    this.userId = 0;
    this.instrument = null;
    this.genre = null;
    this.genres = {};
    this.nickOk = false;
    this.nickChecked = false;
    this.emailOk = false;
    this.emailChecked = false;
  }

  onCheckNicknameDuplication() {
    console.log(this.nickname);
    api.get("/apps/check_nick", { nickname: this.nickname }).then((r) => {
      this.nickChecked = true;
      if (r && r.data && r.data["status"] === "not exist") {
        this.nickOk = true;
      } else {
        this.nickOk = false;
      }
    });
  }

  onCheckEmailDuplication() {
    if (!this.isValidEmail(this.email)) {
      this.emailChecked = false;
      this.alert("Check your email!");
      return;
    }
    console.log(this.email);
    api.get("/apps/check_email", { email: this.email }).then((r) => {
      this.emailChecked = true;
      if (r && r.data && r.data["status"] === "not exist") {
        this.emailOk = true;
      } else {
        this.emailOk = false;
      }
    });
  }

  async alert(msg: string) {
    const alert = await alertController.create({
      cssClass: "custom-alert",
      header: "Alert",
      message: msg,
      buttons: [
        {
          text: "OK",
          cssClass: "alert-button-confirm",
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
  }

  isValidEmail(email: string): boolean {
    const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return expression.test(email);
  }

  isValidPassword(pw: string): boolean {
    const rules = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$");
    return rules.test(pw);
  }

  checkValidity(): boolean {
    if (this.name.length == 0) {
      this.alert("First Name required!");
      return false;
    }
    if (this.surname.length == 0) {
      this.alert("Last Name required!");
      return false;
    }
    if (this.nickChecked == false || this.nickOk == false) {
      this.alert("Check your nickname!");
      return false;
    }
    if (this.emailChecked == false || this.emailOk == false) {
      this.alert("Check your email!");
      return false;
    }
    if (!this.isValidPassword(this.passwd)) {
      this.alert("At least 8 characters long.<br/>A combination of letters, numbers, and symbols.");
      return false;
    }
    if (this.passwd !== this.repasswd) {
      this.alert("Confirm your password!");
      return false;
    }
    return true;
  }

  onFirstNext() {
    console.log("next");
    if (!this.checkValidity()) {
      return;
    }
    const data = {
      name: this.name,
      surname: this.surname,
      nickname: this.nickname,
      email: this.email,
      password: this.passwd,
      userGradeId: 1,
      emailNotification: this.isEmail ? "Y" : "N",
      country: "kr",
    };
    api.post("/users", data).then((r) => {
      if (r && r.data) {
        this.userId = r.data["userId"];
        this.step = 2;
        api.get("/genres").then((r) => {
          if (r && r.data) {
            const genres = r.data["genres"];
            for (const g of genres) {
              console.log(g, g.genreMainId, g.name);
              this.genres[g.genreMainId] = g.name;
            }
          }
        });
      }
    });
  }

  onCancel() {
    this.$router.back();
  }

  onChangeInstrument(value: any) {
    console.log(value);
    const data: { [id: string]: string } = {
      instruments: value,
    };
    api.put(`/apps/user/${this.userId}`, data).then((r) => {
      console.log(r.data);
    });
  }

  onChangeGenre(value: any) {
    console.log(value, value.join(","));
    const data: { [id: string]: string } = {
      genres: value.join(","),
    };
    api.put(`/apps/user/${this.userId}`, data).then((r) => {
      console.log(r.data);
    });
  }

  onDone() {
    this.step = 3;
  }

  onLetsgo() {
    this.ionRouter.push("/tabs/my");
  }
}
