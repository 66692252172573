import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Tabs from "@/views/Tabs.vue";
import Instrument from "@/views/Instrument.vue";
import Genre from "@/views/Genre.vue";
import Login from "@/views/Login.vue";
import SelectEnsemble from "@/views/start/SelectEnsemble.vue";
import SelectInstrument from "@/views/start/SelectInstrument.vue";
import Record from "@/views/start/Record.vue";
import JoinList from "@/views/start/JoinList.vue";
import ViewEnsemble from "@/views/ViewEnsemble.vue";
import Setting from "@/views/Setting.vue";
import Notice from "@/views/Notice.vue";
import ViewNotice from "@/views/ViewNotice.vue";
import Report from "@/views/Report.vue";
import Legal from "@/views/Legal.vue";
import Copyright from "@/views/Copyright.vue";
import Forgot from "@/views/Forgot.vue";
import NewUser from "@/views/NewUser.vue";
import My from "@/views/My.vue";
import Started from "@/views/Started.vue";
import StartedDetail from "@/views/StartedDetail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/tabs/play",
  },
  {
    path: "/tabs/",
    component: Tabs,
    children: [
      {
        path: "",
        redirect: "play",
      },
      {
        path: "play",
        component: () => import("@/views/Play.vue"),
      },
      {
        path: "hall",
        component: () => import("@/views/Hall.vue"),
      },
      {
        path: "my",
        component: () => import("@/views/My.vue"),
      },
      {
        path: "search",
        component: () => import("@/views/Search.vue"),
      },
    ],
  },
  {
    path: "/instrument/:id/:name",
    component: Instrument,
  },
  {
    path: "/genre/:id/:name",
    component: Genre,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/start/ensemble/:titleId/:artistId/:title/:artist/:isRecording",
    component: SelectEnsemble,
  },
  {
    path: "/start/instrument/:productId/:ensembleId/:title/:artist/:isRecording",
    component: SelectInstrument,
  },
  {
    path: "/start/record/:productId/:ensembleId/:partNo/:instrument/:title/:artist/:isRecording",
    component: Record,
  },
  {
    path: "/join/list/:titleId/:artistId/:ensembleSubId/:title/:artist/",
    component: JoinList,
  },
  {
    path: "/started/:titleId/:artistId/:title/:artist/:ensembleSubId",
    component: Started,
  },
  {
    path: "/startedDetail/:recordingId/:includePrivate/:title/:artist",
    component: StartedDetail,
  },
  {
    path: "/ensembles/:ensembleId/:includePrivate:/:isDirect",
    component: ViewEnsemble,
  },
  {
    path: "/setting",
    component: Setting,
  },
  {
    path: "/notice",
    component: Notice,
  },
  {
    path: "/notice/:articleId",
    component: ViewNotice,
  },
  {
    path: "/report",
    component: Report,
  },
  {
    path: "/legal",
    component: Legal,
  },
  {
    path: "/copyright",
    component: Copyright,
  },
  {
    path: "/forgot",
    component: Forgot,
  },
  {
    path: "/newuser",
    component: NewUser,
  },
  {
    path: "/user/:userId",
    component: My
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
