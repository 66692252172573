
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonSegment, IonSegmentButton } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";
import Songs from "@/components/Songs.vue";

@Options({
  name: "Instrument",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    Songs,
    IonSegment,
    IonSegmentButton,
  },
})
export default class Instrument extends Vue {
  api = api;
  ensembleSubId: number | null = null;
  ensembleName: string | null = null;
  segment = "all";

  created() {
    if (this.$route.params && this.$route.params["id"]) {
      this.ensembleSubId = Number(this.$route.params["id"]);
    }
    if (this.$route.params && this.$route.params["name"]) {
      this.ensembleName = String(this.$route.params["name"]);
    }
  }

  onChangeSegment(sel: any) {
    this.segment = sel.detail.value;
    console.log(this.segment);
  }
}
