
import { IonList, IonItem, IonThumbnail, IonLabel, IonNote, IonButton, IonInfiniteScroll, IonInfiniteScrollContent, InfiniteScrollCustomEvent, useIonRouter } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { api, Song } from "@/api/api";

@Options({
  name: "Songs",
  components: {
    IonButton,
    IonList,
    IonItem,
    IonThumbnail,
    IonLabel,
    IonNote,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
})
export default class Songs extends Vue {
  @Prop({ type: String })
  public instruments!: string;

  @Prop({ type: Number })
  public ensembleSubId!: number;

  @Prop({ type: Number })
  public genreSubId!: number;

  @Prop({ type: String })
  public search!: string;

  @Prop({ type: String })
  public filter!: string;

  api = api;
  ionRouter = useIonRouter();
  songs: Song[] = [];
  limit = 25;
  offset = 0;

  @Watch("instruments")
  onChangeInstruments() {
    this.load();
  }

  @Watch("search")
  onChangeSearch() {
    this.load();
  }

  @Watch("filter")
  onChangeFilter() {
    this.load();
  }

  mounted() {
    this.load();
  }

  load(isNew = true) {
    if (isNew) {
      this.limit = 25;
      this.offset = 0;
    }
    const params: { [id: string]: any } = {
      limit: this.limit,
      offset: this.offset,
    };
    if (this.instruments) {
      params["instruments"] = this.instruments;
    }
    if (this.ensembleSubId) {
      params["ensembleSubId"] = this.ensembleSubId;
    }
    if (this.genreSubId) {
      params["genreSubId"] = this.genreSubId;
    }
    if (this.search) {
      params["search"] = this.search;
    }
    if (this.filter) {
      params["joinFilter"] = this.filter;
    }
    console.log("songs", params);
    api.get(`/apps/songs`, params).then((r) => {
      if (r && r.data) {
        if (isNew) {
          this.songs = r.data["songs"];
          this.offset += this.limit;
        } else {
          this.songs = [...this.songs, ...r.data["songs"]];
          this.offset += this.limit;
        }
      }
    });
  }

  onInfiniteScroll(ev: InfiniteScrollCustomEvent) {
    this.load(false);
    setTimeout(() => ev.target.complete(), 500);
  }

  // onStart(song: Song) {
  //   if (!api.user.userId) {
  //     this.ionRouter.push("/login");
  //     return;
  //   }
  //   if (this.ensembleSubId) {
  //     const params = {
  //       titleId: song.titleId,
  //       artistId: song.artistId,
  //       ensembleSubId: this.ensembleSubId,
  //     };
  //     api.get("/apps/products", params).then((r) => {
  //       if (r && r.data && r.data["products"] && r.data["products"][0] && r.data["products"][0]["productId"]) {
  //         const productId = r.data["products"][0]["productId"];
  //         console.log(productId);
  //         this.ionRouter.push(`/start/instrument/${productId}/0/${song.title}/${song.artist}`);
  //       }
  //     });
  //   } else {
  //     this.ionRouter.push(`/start/ensemble/${song.titleId}/${song.artistId}/${song.title}/${song.artist}`);
  //   }
  // }

  // onJoin(song: Song) {
  //   if (this.ensembleSubId) {
  //     this.ionRouter.push(`/join/list/${song.titleId}/${song.artistId}/${this.ensembleSubId}/${encodeURIComponent(song.title)}/${encodeURIComponent(song.artist)}`);
  //   } else {
  //     this.ionRouter.push(`/join/list/${song.titleId}/${song.artistId}/0/${encodeURIComponent(song.title)}/${encodeURIComponent(song.artist)}`);
  //   }
  // }

  onStarted(titleId: number, artistId: number, title: string, artist: string) {
    if (this.ensembleSubId) {
      this.ionRouter.push(`/started/${titleId}/${artistId}/${title}/${artist}/${this.ensembleSubId}`);
    } else {
      this.ionRouter.push(`/started/${titleId}/${artistId}/${title}/${artist}/0/`);
    }
  }
}
