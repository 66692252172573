
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonButton, IonList, IonItem, IonLabel, useIonRouter } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api, ArticleDetail, Asset } from "@/api/api";

@Options({
  name: "ViewNotice",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
  },
})
export default class ViewNotice extends Vue {
  api = api;
  ionRouter = useIonRouter();
  articleId = 0;
  article: ArticleDetail | null = null;
  attachments: Asset[] = [];

  created() {
    if (this.$route.params && this.$route.params["articleId"]) {
      this.articleId = Number(this.$route.params["articleId"]);
    }
  }

  mounted() {
    api.get(`/articles/${this.articleId}`).then((r) => {
      if (r && r.data) {
        this.article = r.data;
        api.get("/attachments", { articleId: this.articleId }).then((r) => {
          if (r && r.data) {
            this.attachments = r.data["attachments"];
          }
        });
      }
    });
  }
}
