import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_Recordings = _resolveComponent("Recordings")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { text: "" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "toolbar-title" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.title), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, { class: "toolbar-artist" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.artist), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_Recordings, {
            titleId: _ctx.titleId,
            artistId: _ctx.artistId,
            joinInstruments: _ctx.api.user.instruments,
            ensembleSubId: _ctx.ensembleSubId,
            isReplace: true
          }, null, 8, ["titleId", "artistId", "joinInstruments", "ensembleSubId"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}