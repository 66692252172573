import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ebe6a4e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "nickname" }
const _hoisted_4 = {
  key: 0,
  class: "description"
}
const _hoisted_5 = {
  key: 1,
  class: "description-edit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_StartedRecordings = _resolveComponent("StartedRecordings")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              (_ctx.user && _ctx.user.userId == _ctx.api.user.userId)
                ? (_openBlock(), _createBlock(_component_ion_buttons, {
                    key: 0,
                    slot: "start"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onEdit()))
                      }, {
                        default: _withCtx(() => [
                          (!_ctx.isEdit)
                            ? (_openBlock(), _createBlock(_component_ion_icon, {
                                key: 0,
                                slot: "icon-only",
                                src: `${_ctx.api.publicPath}/assets/images/ico-pen-2.svg`
                              }, null, 8, ["src"]))
                            : (_openBlock(), _createBlock(_component_ion_icon, {
                                key: 1,
                                slot: "icon-only",
                                icon: _ctx.checkmarkCircleOutline,
                                style: {"color":"#e66516"}
                              }, null, 8, ["icon"]))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_buttons, {
                    key: 1,
                    slot: "start"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClose()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            src: _ctx.close
                          }, null, 8, ["src"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.user && _ctx.user.userId === _ctx.api.user.userId ? "My" : "User"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "primary" }, {
                default: _withCtx(() => [
                  (_ctx.user && _ctx.user.userId == _ctx.api.user.userId)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onMore()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            src: _ctx.ellipsisVertical
                          }, null, 8, ["src"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.user)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("input", {
                  type: "file",
                  accept: "image/*",
                  style: {"display":"none"},
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.importFile($event)))
                }, null, 32),
                _createVNode(_component_ion_avatar, { class: "avatar" }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: _ctx.user.image ? _ctx.api.getAssetUrl(_ctx.user.image) : `${_ctx.api.publicPath}/assets/images/ico-avatar.svg`,
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onChangeAvatar()))
                    }, null, 8, _hoisted_2),
                    (_ctx.isEdit)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "close",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onDeleteAvatar()))
                        }, [
                          _createVNode(_component_ion_icon, { src: _ctx.close }, null, 8, ["src"])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.isEdit)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "camera",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onChangeAvatar()))
                        }, [
                          _createVNode(_component_ion_icon, {
                            src: `${_ctx.api.publicPath}/assets/images/ico-camera-sm.svg`
                          }, null, 8, ["src"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.user.nickName), 1),
                (!_ctx.isEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.user.description), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_ion_textarea, {
                        placeholder: "Introduce yourself.",
                        modelValue: _ctx.user.description,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.user.description) = $event)),
                        counter: true,
                        maxlength: "200",
                        "auto-grow": true
                      }, null, 8, ["modelValue"])
                    ]))
              ]),
              _createVNode(_component_ion_segment, {
                mode: "md",
                value: _ctx.segment,
                onIonChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeSegment($event))),
                style: {"margin-left":"0px","margin-right":"0px","border-radius":"0px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_segment_button, { value: "started" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Started ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_segment_button, { value: "joined" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Joined ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"]),
              _createVNode(_component_StartedRecordings, {
                user: _ctx.user.userId,
                includePrivate: _ctx.userId == null || _ctx.api.user.userId == _ctx.userId,
                isJoin: _ctx.segment === 'joined' ? true : false,
                updateCnt: _ctx.updateCnt,
                isPush: true
              }, null, 8, ["user", "includePrivate", "isJoin", "updateCnt"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}