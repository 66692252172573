
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonButton, IonItem, IonLabel, IonNote, IonIcon, useIonRouter, IonAvatar } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api, StartedRecordingDetail } from "@/api/api";
// import StartedRecordings from "@/components/StartedRecordings.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import { lockClosed } from "ionicons/icons";
import { getPlatforms } from "@ionic/vue";
import { ScreenOrientation, ScreenOrientationResult } from "@capacitor/screen-orientation";
// import { App } from "@capacitor/app";

@Options({
  name: "StartedDetail",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonButton,
    IonItem,
    IonLabel,
    IonNote,
    IonIcon,
    IonAvatar,
    VideoPlayer,
  },
})
export default class StartedDetail extends Vue {
  api = api;
  ionRouter = useIonRouter();
  lockClosed = lockClosed;

  recordingId: number | null = null;
  includePrivate: boolean | null = null;
  title: string | null = null;
  artist: string | null = null;

  recording: StartedRecordingDetail | null = null;
  playCmd = "";

  isLandscape = false;

  created() {
    if (this.$route.params && this.$route.params["recordingId"]) {
      this.recordingId = Number(this.$route.params["recordingId"]);
    }
    if (this.$route.params && this.$route.params["includePrivate"]) {
      this.includePrivate = Boolean(this.$route.params["includePrivate"]);
    }
    if (this.$route.params && this.$route.params["title"]) {
      this.title = String(this.$route.params["title"]);
    }
    if (this.$route.params && this.$route.params["artist"]) {
      this.artist = String(this.$route.params["artist"]);
    }
  }

  mounted() {
    this.load();
  }

  ionViewWillEnter() {
    console.log("will enter");
    if (this.isIPad()) {
      ScreenOrientation.orientation().then((r: ScreenOrientationResult) => {
        if (r.type === "landscape-primary" || r.type === "landscape-secondary") {
          this.isLandscape = true;
        } else if (r.type === "portrait-primary" || r.type === "portrait-secondary") {
          this.isLandscape = false;
        }
        console.log("orientation", r.type, this.isLandscape);
      });
      ScreenOrientation.addListener("screenOrientationChange", (r: ScreenOrientationResult) => {
        console.log("orientation", r.type);
        ScreenOrientation.orientation().then((r: ScreenOrientationResult) => {
          if (r.type === "landscape-primary" || r.type === "landscape-secondary") {
            this.isLandscape = true;
          } else if (r.type === "portrait-primary" || r.type === "portrait-secondary") {
            this.isLandscape = false;
          }
          console.log("orientation changed", r.type, this.isLandscape);
        });
      });
    }

    this.playCmd = "";
    if (api.needUpdate) {
      this.load();
      api.needUpdate = false;
    }
    // App.addListener("appStateChange", (state: any) => {
    //   if (state.isActive == false) {
    //     this.playCmd = "pause";
    //   } else {
    //     this.playCmd = "";
    //   }
    // });
  }

  ionViewWillLeave() {
    if (this.isIPad()) {
      ScreenOrientation.removeAllListeners();
    }
    console.log("will leave");
    this.playCmd = "pause";
    // App.removeAllListeners();
  }

  load() {
    const params: { [id: string]: any } = {};
    // SHOW_ALL_LIST
    // if (api.user.instruments != null) {
    //   params["joinInstruments"] = api.user.instruments;
    // }
    if (this.includePrivate) {
      params["includePrivate"] = this.includePrivate;
      if (api.user && api.user.userId) {
        params["userMe"] = api.user.userId;
      }
    }
    api.get(`/apps/started/${this.recordingId}`, params).then((r) => {
      if (r && r.data) {
        this.recording = r.data;
        console.log(this.recording);
      }
    });
  }

  getInstrumentsString(s: string) {
    const insts = s.split(",").map((item) => item.trim());
    return insts.join(" + ");
  }

  onView(ensembleId: number) {
    this.playCmd = "pause";
    this.ionRouter.push(`/ensembles/${ensembleId}/true/false`);
  }

  goToUser(userId: number) {
    this.playCmd = "pause";
    if (userId === api.user.userId) {
      this.ionRouter.replace(`/tabs/my`);
    } else {
      this.ionRouter.push(`/user/${userId}`);
    }
  }

  onStart(isRecording: boolean) {
    this.playCmd = "pause";
    if (!api.user.userId) {
      this.ionRouter.push("/login");
      return;
    }
    if (this.recording && this.title && this.artist) {
      this.ionRouter.push(`/start/instrument/${this.recording.productId}/0/${encodeURIComponent(this.title)}/${encodeURIComponent(this.artist)}/${isRecording}`);
    }
  }

  onJoin(productId: number, ensembleId: number, title: string, artist: string) {
    this.playCmd = "pause";
    if (!api.user.userId) {
      this.ionRouter.push("/login");
      return;
    }
    this.ionRouter.push(`/start/instrument/${productId}/${ensembleId}/${encodeURIComponent(title)}/${encodeURIComponent(artist)}/true`);
  }

  isIPad() {
    const platforms = getPlatforms();
    console.log("platforms", platforms);
    return platforms.indexOf("ipad") != -1;
  }
}
