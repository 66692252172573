
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Recording, api } from "@/api/api";
import Player from "@/components/Player.vue";
import { ScreenOrientation, ScreenOrientationResult } from "@capacitor/screen-orientation";
import { IonAvatar, IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonLabel, IonNote, IonPage, IonTitle, IonToolbar, actionSheetController, alertController, getPlatforms, useBackButton, useIonRouter, IonRange } from "@ionic/vue";
import { VideoPlayer } from "@videojs-player/vue";
import { chevronBack, close, ellipse, pauseCircle, playCircle, square } from "ionicons/icons";
import { Options, Vue } from "vue-class-component";
import WaveSurfer from "wavesurfer.js";
import { WaveSurferParams } from "wavesurfer.js/types/params";
import { App } from "@capacitor/app";

enum RecordState {
  None = 0,
  Recorded = 1,
  Prepare = 2,
  Recording = 3,
  Pause = 4,
}

let gws: WaveSurfer | undefined = undefined;
let posterUrl: string | undefined = undefined;

class EndTimer {
  callback: () => void;
  endTimer: any = null;
  endTimerLeft = 10;
  endTiemrStart = new Date();

  constructor(callback: () => void, timeout: number) {
    this.callback = callback;
    this.endTimerLeft = timeout;
    this.endTiemrStart = new Date();
    if (timeout > 0) {
      this.endTimer = setTimeout(() => {
        this.callback();
      }, this.endTimerLeft);
      console.log("end timer started");
    }
  }

  getLeft(): number {
    return this.endTimerLeft;
  }

  pause() {
    if (this.endTimer != null) {
      this.endTimerLeft -= new Date().getTime() - this.endTiemrStart.getTime();
      clearInterval(this.endTimer);
      this.endTimer = null;
      console.log("end timer pased", this.endTimerLeft);
    }
  }

  resume() {
    if (this.endTimerLeft > 0) {
      this.endTiemrStart = new Date();
      this.endTimer = setTimeout(() => {
        this.callback();
      }, this.endTimerLeft);
      console.log("end timer resumed", this.endTimerLeft);
    }
  }

  clear() {
    if (this.endTimer != null) {
      clearTimeout(this.endTimer);
      this.endTimer = null;
      this.endTimerLeft = 0;
      console.log("end tiemr cleared");
    } else {
      this.endTimerLeft = 0;
    }
  }
}

@Options({
  name: "Record",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonLabel,
    IonNote,
    IonIcon,
    IonFooter,
    VideoPlayer,
    Player,
    IonAvatar,
    IonRange,
  },
})
export default class Record extends Vue {
  api = api;
  ionRouter = useIonRouter();
  ellipse = ellipse;
  square = square;
  chevronBack = chevronBack;
  close = close;
  playCircle = playCircle;
  pauseCircle = pauseCircle;

  productId: number | null = null;
  partNo = 0;
  instrument: string | null = null;
  title: string | null = null;
  artist: string | null = null;
  isRecording: boolean | null = null;
  xml: string | null = null;
  recordState: RecordState = RecordState.None;
  count = 0;
  mediaStream: MediaStream | null = null;
  mediaRecorder: MediaRecorder | null = null;
  videoMime = "video/mp4";
  videoBlob: Blob[] = [];
  videoUrl: string | undefined = undefined;

  ensembleId = 0;
  ensemble: Recording[] = [];
  playerCommand = "";

  tempo = 0;
  initTempo = 0;
  duration = 0;
  progress = 0;
  current = 0;
  isRecordEnd = false;

  wavesurfer!: WaveSurfer;
  isWavePlaying = false;
  isSynchronizing = false;
  waveProgress = 0;
  waveCurrent = 0;
  waveDuration = 0;
  syncValue = 0;
  volumeValue = 100;
  fromSync = false;

  isUploding = false;
  uploadPercentage = 0;

  recordedParts: number[] = [];
  emptyParts: string[] = [];

  audioContext!: AudioContext;

  perf = 0;

  scrollMode = "automatic";
  endTimer = new EndTimer(() => {
    console.log("null timer");
  }, 0);
  isLandscape = false;
  isLandscapeAndTimed = false;
  isJoin = false;
  currentOrientation = "portrait-primary";

  isRecorderLoaded = false;

  appStateHandler: any;
  prebeatDuration = 0;

  get progressPercent() {
    return `--percent: ${this.uploadPercentage}`;
  }

  created() {
    if (this.$route.params && this.$route.params["productId"]) {
      this.productId = Number(this.$route.params["productId"]);
    }
    if (this.$route.params && this.$route.params["ensembleId"]) {
      this.ensembleId = Number(this.$route.params["ensembleId"]);
    }
    if (this.$route.params && this.$route.params["partNo"]) {
      this.partNo = Number(this.$route.params["partNo"]);
    }
    if (this.$route.params && this.$route.params["instrument"]) {
      this.instrument = String(this.$route.params["instrument"]);
    }
    if (this.$route.params && this.$route.params["title"]) {
      this.title = String(this.$route.params["title"]);
    }
    if (this.$route.params && this.$route.params["artist"]) {
      this.artist = String(this.$route.params["artist"]);
    }
    if (this.$route.params && this.$route.params["isRecording"]) {
      this.isRecording = this.$route.params["isRecording"] === "true" ? true : false;
    }

    useBackButton(10, () => {
      this.onBack();
    });
  }

  onMessage = (e: any) => {
    switch (e.detail["message"]) {
      case "tempo":
        this.tempo = e.detail["value"]["tempo"];
        // console.log("tempo", this.tempo);
        break;
      case "duration":
        this.duration = e.detail["value"]["duration"];
        console.log("duration", this.duration);
        break;
      case "progress":
        this.progress = e.detail["value"]["progress"];
        this.updateRange("#progress", this.progress);
        this.current = e.detail["value"]["current"];
        this.duration = e.detail["value"]["total"];
        // console.log("progress", this.progress);
        break;
      case "ended":
        console.log("duration", this.duration);
        this.endTimer = new EndTimer(
          () => {
            this.isRecordEnd = true;
            this.stopRecord();
            this.endTimer.clear();
            console.log("ended");
            api.setCaffeine(false);
          },
          this.scrollMode === "manual" ? this.duration * 1000 : 10000
        );
        break;
      case "seek": {
        const progress = e["detail"]["value"]["progress"];
        const sec = Math.round(progress * this.duration) / 100;
        if (gws) {
          // console.log((e.data["value"]["progress"] * this.duration) / gws.getDuration(), e.data["value"]["progress"], this.duration, gws.getDuration(), e);
          gws.seekTo((progress * this.duration) / gws.getDuration());
          gws.skip(this.syncValue);
        }
        console.log("seek from mxml", progress, sec);
        // this.playerCommand = "seek_" + sec;
        break;
      }
      case "play": {
        this.playerCommand = "play";
        this.lockCurrentOrientation();
        api.setCaffeine(true);
        this.postProductPlay();
        break;
      }
      case "pause": {
        this.playerCommand = "pause";
        this.unlockScreenOrientation();
        api.setCaffeine(false);
        break;
      }
      case "recording": {
        const isContinue = e["detail"]["value"]["continue"];
        console.log("recording started", isContinue);
        this.doRecord(isContinue == true ? true : false);
        if (this.ensembleId) {
          this.playerCommand = "play";
        }
        api.setCaffeine(true);
        break;
      }
      case "scroll": {
        const mode = e["detail"]["value"]["mode"];
        this.scrollMode = mode;
        console.log("scroll mode", this.scrollMode);
        break;
      }
      case "prebeat": {
        console.log("prebeat", e);
        const prebeatDuration = e["detail"]["value"]["duration"];
        this.prebeatDuration = prebeatDuration;
        console.log("prebeat", prebeatDuration);
        break;
      }
    }
    // console.log(e.data);
  };

  onHorizontalMessage = (e: any) => {
    switch (e.detail["message"]) {
      case "duration":
        this.duration = e.detail["value"]["duration"];
        console.log("duration", this.duration);
        break;
      case "seek": {
        const progress = e["detail"]["value"]["progress"];
        const sec = Math.round(progress * this.duration) / 100;
        if (gws) {
          // console.log((e.data["value"]["progress"] * this.duration) / gws.getDuration(), e.data["value"]["progress"], this.duration, gws.getDuration(), e);
          console.log("progress", progress, "prebeat", this.prebeatDuration, "duration", this.duration, "wave duration", gws.getDuration(), "wave progress", this.waveProgress, "seekto", (progress * this.duration + this.prebeatDuration / 1000) / gws.getDuration());
          if (this.waveProgress <= (this.prebeatDuration / 1000 / gws.getDuration()) * 100 || this.waveProgress >= (this.duration / gws.getDuration()) * 100) {
            gws.seekTo(this.waveProgress / 100);
            gws.skip(this.syncValue);
          } else {
            gws.seekTo((progress * this.duration + this.prebeatDuration / 1000) / gws.getDuration());
            gws.skip(this.syncValue);
          }
        }
        console.log("seek from mxml", progress, sec);
        this.playerCommand = "seek_" + sec;
        break;
      }
    }
    // console.log(e.data);
  };

  onBack(): void {
    if (this.recordState > RecordState.Recorded) {
      // TEMPORARY IMPL FOR DEV
      // if (this.recordState == RecordState.Prepare) {
      //   this.recordState = RecordState.None;
      //   this.videoBlob = [];
      //   posterUrl = undefined;
      //   this.postToChild("stop");
      // } else {
      //   this.isRecordEnd = true;
      //   this.postToChild("pause");
      //   if (this.ensembleId) {
      //     this.playerCommand = "pause";
      //   }
      // }
      // this.stopRecord();
      // TEMPORARY COMMENT OUT FOR DEV
      if (this.recordState == RecordState.Recording) {
        this.postToChild("pause");
      }
      if (this.recordState == RecordState.Prepare) {
        this.confirmQuit();
      }
      this.stopRecord();
      if (this.recordState == RecordState.Recording) {
        this.onPause();
      }
    } else if (this.recordState == RecordState.Recorded) {
      if (this.isSynchronizing) {
        this.isSynchronizing = false;
        if (this.isWavePlaying) {
          this.onWavePlay();
        }
      } else {
        this.unlockScreenOrientation();
        this.endTimer.clear();
        if (this.isWavePlaying) {
          this.onWavePlay();
        }
        gws = undefined;
        this.recordState = RecordState.None;
        this.videoBlob = [];
        posterUrl = undefined;
        this.postToChild("rewind");
        this.postToChild("stop");
        const platforms = getPlatforms();
        if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
          api.commandNativePlayer("show");
        }
        this.showRecorderPreview(true);
      }
      // this.postToChild("solo_sound_off");
    } else {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
        this.mediaStream = null;
      }
      // const platforms = getPlatforms();
      // if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
      //   api.closeNativePlayer(this.ensembleId);
      // }
      this.showRecorderPreview(false);
      this.postToChild("stop");
      this.$router.back();
    }
    this.postToChild("pause");
  }

  ionViewWillEnter() {
    console.log(this.productId, this.partNo, this.title, this.artist);
    this.isLandscape = false;
    this.unlockScreenOrientation();
    ScreenOrientation.addListener("screenOrientationChange", (r: ScreenOrientationResult) => {
      console.log("orientation", r.type);
      if (this.recordState == RecordState.None) {
        this.isLandscapeAndTimed = false;
        ScreenOrientation.orientation().then((r: ScreenOrientationResult) => {
          if (r.type === "landscape-primary" || r.type === "landscape-secondary") {
            this.isLandscape = true;
          } else if (r.type === "portrait-primary") {
            this.isLandscape = false;
          } else if (this.isIPad() && r.type === "portrait-secondary") {
            this.isLandscape = false;
          }
          this.currentOrientation = r.type;
          console.log("orientation changed", r.type, this.isLandscape);
          setTimeout(() => {
            this.isLandscapeAndTimed = true;
            const player = document.getElementById("preview") as HTMLVideoElement;
            const left = (player.parentElement as HTMLElement).getBoundingClientRect().left;
            const top = (player.parentElement as HTMLElement).getBoundingClientRect().top;
            const width = (player.parentElement as HTMLElement).getBoundingClientRect().width;
            const height = (player.parentElement as HTMLElement).getBoundingClientRect().height;
            let degree = 90;
            if (r.type === "landscape-primary") {
              degree = 0;
            } else if (r.type === "landscape-secondary") {
              degree = 180;
            } else if (this.isIPad() && r.type === "portrait-secondary") {
              degree = 270;
            }
            if (this.isIPad() || r.type !== "portrait-secondary") {
              api.rotateNativeRecorder(left, top, width, height, degree);
            }
          }, 300);
          console.log("rotate", api.playerHeight);
        });
      }
    });
    this.recordState = RecordState.None;
    this.recordedParts = [];
    api.get(`/products/${this.productId}`).then((rp) => {
      if (rp && rp.data) {
        console.log(rp.data);
        api.get(`/products/${this.productId}/parts`).then((rpp) => {
          if (rpp && rpp.data) {
            console.log(rpp.data);
            let emptyParts = rpp.data["parts"].map((p: any) => p["instrument_code"]);
            delete emptyParts[this.partNo - 1];
            console.log(this.emptyParts);
            if (this.ensembleId > 0) {
              this.isJoin = true;
              api.get(`/apps/recordings/${this.ensembleId}`).then((r) => {
                if (r && r.data) {
                  this.ensemble = [r.data["recording"]];
                  console.log(this.ensemble);
                  if (this.ensemble.length > 0 && this.ensemble[0].tempo) {
                    this.initTempo = this.ensemble[0].tempo;
                    this.tempo = this.initTempo;
                  }

                  if (process.env.NODE_ENV === "production") {
                    this.xml = `${api.getAssetUrl(rp.data["xml"])}`;
                  } else {
                    this.xml = `${api.getAssetUrl(rp.data["xml"])}`;
                  }
                  this.loadScore();

                  this.ensemble[0].users = this.ensemble[0].users.filter((u) => u.no != this.partNo);
                  this.ensemble[0].users.forEach((u) => {
                    delete emptyParts[u.no - 1];
                  });

                  this.emptyParts = [];
                  emptyParts.forEach((ep: any) => {
                    if (ep) {
                      this.emptyParts.push(ep);
                    }
                  });
                  console.log(this.emptyParts);

                  // if (score) {
                  //   if (this.partNo) {
                  //     score.parts = this.partNo - 1;
                  //   }
                  //   if (this.tempo) {
                  //     score.tempo = this.tempo;
                  //   }
                  // }
                  // if (this.partNo) {
                  //   this.xml += `&parts=${this.partNo - 1}`;
                  // }
                  // if (this.tempo) {
                  //   this.xml += `&tempo=${this.tempo}`;
                  // }
                  console.log(this.xml);
                }
              });
              api.get(`/apps/recordings/${this.ensembleId}/parts`).then((er) => {
                if (er && er.data) {
                  this.recordedParts = er.data["parts"].map((e: { [x: string]: any }) => Number(e["no"]) - 1);
                  this.recordedParts = this.recordedParts.filter((e) => e != this.partNo - 1);
                }
              });
            } else {
              this.isJoin = false;
              if (process.env.NODE_ENV === "production") {
                this.xml = `${api.getAssetUrl(rp.data["xml"])}`;
              } else {
                this.xml = `${api.getAssetUrl(rp.data["xml"])}`;
              }
              this.loadScore();
              // if (this.partNo) {
              //   this.xml += `&parts=${this.partNo - 1}`;
              // }
              this.emptyParts = [];
              emptyParts.forEach((ep: any) => {
                if (ep) {
                  this.emptyParts.push(ep);
                }
              });
            }
          }
        });
      }
    });

    const isChrome = navigator.userAgent.toLowerCase().indexOf("chrome") != -1;
    this.videoMime = isChrome ? "video/webm" : "video/mp4";
    const platforms = getPlatforms();
    if (platforms.indexOf("android") != -1 && platforms.indexOf("mobileweb") == -1) {
      this.videoMime = "video/mp4";
    }
    const preview = this.$el.querySelector("#preview");
    console.log("preview", preview);
    if (preview) {
      preview.poster = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
    }

    App.addListener("appStateChange", (state: any) => {
      if (state.isActive == false) {
        this.postToChild("pause");
        // this.playerCommand = "pause";
        // if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
        //   api.commandNativePlayer("show");
        // }
        // if (this.isWavePlaying) {
        //   gws?.pause();
        // }
        // } else {
        // this.playerCommand = "";
      }
    });
  }

  ionViewDidEnter() {
    this.isRecorderLoaded = false;
    this.showRecorderPreview(true);
    if (this.isRecording) {
      api.isWiredHeadphone().then((r) => {
        if (!r) {
          this.showUseHeadphone();
        }
      });
    }
  }

  showRecorderPreview(isShow: boolean) {
    const platforms = getPlatforms();
    if ((platforms.indexOf("android") != -1 || platforms.indexOf("ios") != -1) && platforms.indexOf("mobileweb") == -1) {
      if (isShow) {
        this.isLandscapeAndTimed = false;
        ScreenOrientation.orientation().then((r: ScreenOrientationResult) => {
          let degree = 90;
          if (r.type === "landscape-primary") {
            degree = 0;
          } else if (r.type === "landscape-secondary") {
            degree = 180;
          }
          this.isLandscape = degree != 90 ? true : false;
          this.currentOrientation = r.type;
          console.log("orientation preview", this.currentOrientation, this.isLandscape);
          this.isLandscapeAndTimed = true;
          if (this.isRecording) {
            console.log("check permission");
            api.checkPermission().then((granted: boolean) => {
              if (granted) {
                const player = document.getElementById("preview") as HTMLVideoElement;
                api.openNativeRecorder((player.parentElement as HTMLElement).getBoundingClientRect().left, (player.parentElement as HTMLElement).getBoundingClientRect().top, (player.parentElement as HTMLElement).getBoundingClientRect().width, (player.parentElement as HTMLElement).getBoundingClientRect().height, degree).then(() => {
                  api.commandNativeRecorder("prepare");
                  this.isRecorderLoaded = true;
                  setTimeout(() => {
                    const player = document.getElementById("preview") as HTMLVideoElement;
                    api.rotateNativeRecorder((player.parentElement as HTMLElement).getBoundingClientRect().left, (player.parentElement as HTMLElement).getBoundingClientRect().top, (player.parentElement as HTMLElement).getBoundingClientRect().width, (player.parentElement as HTMLElement).getBoundingClientRect().height, degree);
                  }, 1000);
                });
              } else {
                api.requestPermission().then(() => {
                  console.log("request permission");
                });
              }
              this.unlockScreenOrientation();
            });
          } else {
            console.log("skip check permission");
            this.unlockScreenOrientation();
          }
        });
      } else {
        api.closeNativeRecorder();
      }
    }
  }

  ionViewDidLeave() {
    if (!this.isIPad()) {
      ScreenOrientation.lock({ orientation: "portrait" });
    }
  }

  ionViewWillLeave() {
    ScreenOrientation.removeAllListeners();
    const platforms = getPlatforms();
    if ((platforms.indexOf("android") != -1 || platforms.indexOf("ios") != -1) && platforms.indexOf("mobileweb") == -1) {
      this.postToChild("pause");
      api.closeNativeRecorder();
      if (platforms.indexOf("ios") != -1 && this.ensembleId) {
        this.playerCommand = "close";
      }
    }
    App.removeAllListeners();
  }

  prepareRecord(callback: () => void): void {
    const player = document.getElementById("preview") as HTMLVideoElement;
    try {
      navigator.mediaDevices.getUserMedia({ audio: true, video: { width: { min: 640, max: 640 }, height: { min: 480, max: 480 }, frameRate: { ideal: 30, max: 30 } } }).then((stream) => {
        this.mediaStream = stream;
        player.srcObject = stream;
        // const video = this.audioContext.createMediaElementSource(player);
        // video.connect(this.audioContext.destination);
        callback();
      });
    } catch (e) {
      console.log(e);
    }
  }

  doRecord(isContinue: boolean): void {
    this.perf = performance.now();
    const platforms = getPlatforms();
    if ((platforms.indexOf("android") != -1 || platforms.indexOf("ios") != -1) && platforms.indexOf("mobileweb") == -1) {
      this.recordState = RecordState.Recording;
      if (isContinue) {
        api.commandNativeRecorder("resume");
      } else {
        api.commandNativeRecorder("start");
      }
      return;
    }
    const options = { mimeType: this.videoMime };
    console.log(options);
    this.recordState = RecordState.Recording;
    this.mediaRecorder = new MediaRecorder(this.mediaStream as MediaStream, options);
    this.mediaRecorder.addEventListener("dataavailable", (e) => {
      if (e.data.size > 0) {
        this.videoBlob.push(e.data);
      }
    });
    this.mediaRecorder.addEventListener("stop", this.stopRecordCallback.bind(this));
    this.mediaRecorder.start();

    // if (!posterUrl) {
    //   posterUrl = this.getPoster();
    //   console.log(posterUrl);
    // }
  }

  checkPermission(): void {
    const platforms = getPlatforms();
    if ((platforms.indexOf("android") != -1 || platforms.indexOf("ios") != -1) && platforms.indexOf("mobileweb") == -1) {
      api.checkPermission().then((granted: boolean) => {
        if (granted) {
          if (this.isRecorderLoaded) {
            api.isExternalMic().then((r) => {
              if (r) {
                this.showMicUsage(() => {
                  this.onRecord(false);
                });
              } else {
                this.onRecord(false);
              }
            });
          } else {
            ScreenOrientation.orientation().then((r: ScreenOrientationResult) => {
              let degree = 90;
              if (r.type === "landscape-primary") {
                degree = 0;
              } else if (r.type === "landscape-secondary") {
                degree = 180;
              }
              this.isLandscape = degree != 90 ? true : false;
              this.currentOrientation = r.type;
              console.log("orientation preview", this.currentOrientation, this.isLandscape);
              const player = document.getElementById("preview") as HTMLVideoElement;
              api.openNativeRecorder((player.parentElement as HTMLElement).getBoundingClientRect().left, (player.parentElement as HTMLElement).getBoundingClientRect().top, (player.parentElement as HTMLElement).getBoundingClientRect().width, (player.parentElement as HTMLElement).getBoundingClientRect().height, degree).then(() => {
                api.commandNativeRecorder("prepare");
                this.isRecorderLoaded = true;
                setTimeout(() => {
                  const player = document.getElementById("preview") as HTMLVideoElement;
                  api.rotateNativeRecorder((player.parentElement as HTMLElement).getBoundingClientRect().left, (player.parentElement as HTMLElement).getBoundingClientRect().top, (player.parentElement as HTMLElement).getBoundingClientRect().width, (player.parentElement as HTMLElement).getBoundingClientRect().height, degree);
                  api.isExternalMic().then((r) => {
                    if (r) {
                      this.showMicUsage(() => {
                        this.onRecord(false);
                      });
                    } else {
                      this.onRecord(false);
                    }
                  });
                }, 1000);
              });
            });
          }
        } else {
          api.requestPermission().then(() => {
            console.log("request permission");
          });
        }
      });
      return;
    }
    this.onRecord(false);
  }

  onRecord(isContinue: boolean): void {
    // if (!isContinue) {
    //   if (this.recordedParts.length > 0) {
    //     this.postToChild("mute_" + this.recordedParts.join(","));
    //   }
    // }
    // if (this.recordState == RecordState.None || this.recordState == RecordState.Recorded || this.recordState == RecordState.Pause) {
    console.log("onRecord", this.recordState, isContinue);
    this.postToChild("pause");
    if (this.recordState !== RecordState.Pause) {
      this.videoBlob = [];
      this.videoUrl = undefined;
      this.postToChild("rewind");
      if (this.ensembleId) {
        this.playerCommand = "rewind";
      }
    }
    this.recordState = RecordState.Prepare;
    this.count = 0;
    this.progress = 0;
    this.postToChild("prepare");
    api.setCaffeine(true);

    this.lockCurrentOrientation();

    const player = document.getElementById("preview") as HTMLVideoElement;
    console.log((player.parentElement as HTMLElement).getBoundingClientRect());

    const platforms = getPlatforms();
    if ((platforms.indexOf("android") != -1 || platforms.indexOf("ios") != -1) && platforms.indexOf("mobileweb") == -1) {
      this.prepareCallback();
    } else {
      this.prepareRecord(this.prepareCallback);
    }
    // } else {
    //   if (this.recordState == RecordState.Recording) {
    //     this.postToChild("pause");
    //   }
    //   this.stopRecord();
    // }
  }

  prepareCallback(isContinue = false) {
    // this.isRecordEnd = false;
    // this.postToChild("record");
    if (this.recordState == RecordState.Prepare) {
      this.count = 4;
      const t = setInterval(() => {
        if (this.recordState == RecordState.Prepare) {
          this.count -= 1;
          if (this.count < 0) {
            this.isRecordEnd = false;
            this.doRecord(isContinue);
            if (this.endTimer.getLeft() > 0) {
              this.endTimer.resume();
            } else {
              this.postProductPlay();
              const platforms = getPlatforms();
              if (platforms.indexOf("android") != -1 || platforms.indexOf("ios") != -1) {
                setTimeout(() => {
                  this.postToChild("record");
                }, 200);
              } else {
                this.postToChild("record");
              }
              if (this.ensembleId) {
                this.playerCommand = "play";
              }
              clearInterval(t);
            }
          }
        } else {
          clearInterval(t);
        }
      }, 1000);
      // const t = setInterval(() => {
      //   this.count -= 1;
      //   if (this.recordState == RecordState.Prepare) {
      //     if (this.count == 0) {
      //       this.isRecordEnd = false;
      //       this.doRecord(isContinue);
      //     }
      //   } else if (this.count < 0) {
      //     this.postToChild("record");
      //     if (this.ensembleId) {
      //       this.playerCommand = "play";
      //     }
      //     clearInterval(t);
      //   } else {
      //     clearInterval(t);
      //   }
      // }, 1000);
    }
  }

  stopRecord() {
    const platforms = getPlatforms();
    console.log("recording ended", this.recordState);
    if ((platforms.indexOf("android") != -1 || platforms.indexOf("ios") != -1) && platforms.indexOf("mobileweb") == -1) {
      console.log("native", this.isRecordEnd);
      if (this.isRecordEnd) {
        api.closeNativeRecorder().then((b64: string) => {
          this.videoBlob = [this.b64toBlob(b64, "video/mp4")];
          this.videoUrl = URL.createObjectURL(this.videoBlob[0]);
          // console.log("mmm video", this.videoUrl);
          this.stopRecordCallback();
        });
      } else {
        api.commandNativeRecorder("pause");
        setTimeout(() => {
          this.stopRecordCallback();
        }, 100);
      }
    } else {
      console.log("web", this.isRecordEnd, this.mediaRecorder);
      if (this.isRecordEnd) {
        this.stopRecordCallback();
      }
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
        this.mediaStream = null;
      }
    }
    if (this.recordState == RecordState.Prepare) {
      this.recordState = RecordState.Pause;
    }
    if (this.ensembleId) {
      this.playerCommand = "pause";
    }
    const duration = Math.round((performance.now() - this.perf) / 100) / 10;
    console.log("recording duration: ", duration);
  }

  b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  stopRecordCallback() {
    console.log("stop record callback", this.isRecordEnd);
    if (this.isRecordEnd) {
      if (!this.isIPad()) {
        ScreenOrientation.lock({ orientation: "portrait" });
        this.isLandscape = false;
        this.currentOrientation = "portrait-primary";
        console.log("orientation lock", this.currentOrientation, this.isLandscape);
      }
      const platforms = getPlatforms();
      if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
        api.commandNativePlayer("hide");
      }
      if ((platforms.indexOf("android") == -1 && platforms.indexOf("ios") == -1) || platforms.indexOf("mobileweb") != -1) {
        console.log("no native video");
        this.videoUrl = URL.createObjectURL(new Blob(this.videoBlob, { type: this.videoMime }));
      }
      // this.videoUrl = "https://belokan.net/assets/70c3368b-eff9-11ed-9832-9d42c464cdda.mp4"; // failed on ios
      // this.videoUrl = "https://belokan.net/assets/76ddd40c-e8ca-11ed-9954-dda6209a1b38.mp4"; // success on ios
      this.generateVideoThumbnail(String(this.videoUrl)).then((value) => {
        posterUrl = String(value);
      });
      // console.log(this.videoUrl);
      this.recordState = RecordState.Recorded;
      setTimeout(() => {
        this.loadWaveform();
      });
    } else {
      this.recordState = RecordState.Pause;
    }
  }

  async onPause() {
    if (this.scrollMode === "manual") {
      this.confirmQuit();
      return;
    }
    this.endTimer.pause();
    const actionSheet = await actionSheetController.create({
      cssClass: "custom-action-sheet",
      buttons: [
        {
          text: "Quit",
          role: "destructive",
          data: {
            action: "quit",
          },
        },
        {
          text: "Re-start",
          role: "destructive",
          data: {
            action: "restart",
          },
        },
        {
          text: "Continue",
          role: "cancel",
          data: {
            action: "continue",
          },
        },
      ],
      backdropDismiss: false,
    });

    await actionSheet.present();

    const res = await actionSheet.onDidDismiss();
    console.log(res);
    if (res["data"]["action"] === "restart") {
      this.confirmRestart();
    } else if (res["data"]["action"] === "quit") {
      this.confirmQuit();
    } else {
      this.onRecord(true);
    }
  }

  async confirmQuit() {
    this.endTimer.pause();
    this.postToChild("pause");
    let buttons = [
      {
        text: "Quit the recording",
        role: "quit",
        cssClass: "alert-button-default",
      },
      {
        text: "Continue",
        role: "continue",
        cssClass: "alert-button-default",
      },
    ];
    console.log("state", this.recordState, "scrollMode", this.scrollMode);
    if (this.scrollMode === "manual" && this.recordState != RecordState.Prepare) {
      buttons.pop();
      buttons.push({
        text: "Save",
        role: "save",
        cssClass: "alert-button-confirm",
      });
    }
    const alert = await alertController.create({
      cssClass: "custom-alert",
      message: "Are you sure you want to quit the recording?<br/>If you quit, the recording will be deleted",
      buttons: buttons,
      backdropDismiss: false,
    });
    await alert.present();

    const { role } = await alert.onDidDismiss();
    if (role === "quit") {
      this.unlockScreenOrientation();
      this.endTimer.clear();
      this.recordState = RecordState.None;
      this.videoBlob = [];
      posterUrl = undefined;
      this.postToChild("stop");
      const platforms = getPlatforms();
      if ((platforms.indexOf("android") != -1 || platforms.indexOf("ios") != -1) && platforms.indexOf("mobileweb") == -1) {
        api.restartNativeRecorder();
      }
      api.setCaffeine(false);
    } else if (role === "continue") {
      this.onRecord(true);
    } else if (role === "save") {
      this.isRecordEnd = true;
      this.stopRecord();
      console.log("ended");
      this.postToChild("stop");
    }
    console.log(role);
  }

  async confirmRestart() {
    this.endTimer.pause();
    const alert = await alertController.create({
      cssClass: "custom-alert",
      message: "Are you sure you want to Re-Start the recording?<br/>If you re-Start, the recording will be deleted.",
      buttons: [
        {
          text: "Re-start the recording",
          role: "quit",
          cssClass: "alert-button-default",
        },
        {
          text: "Continue",
          role: "continue",
          cssClass: "alert-button-confirm",
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    if (role === "quit") {
      this.endTimer.clear();
      this.recordState = RecordState.None;
      this.videoBlob = [];
      posterUrl = undefined;
      this.progress = 0;
      this.updateRange("#progress", this.progress);
      this.onRecord(false);
    } else if (role === "continue") {
      this.endTimer.resume();
      this.onRecord(true);
    }
    console.log(role);
  }

  async onSave() {
    if (this.isWavePlaying) {
      gws?.pause();
      this.postToChild("pause");
    }
    const actionSheet = await actionSheetController.create({
      cssClass: "custom-action-sheet",
      header: "Upload Video",
      subHeader: "It will share my performance with my friends. or it will listen alone.",
      buttons: [
        {
          text: "Public",
          role: "destructive",
          data: {
            action: "public",
          },
        },
        {
          text: "Private",
          role: "destructive",
          data: {
            action: "private",
          },
        },
        {
          text: "Cancel",
          role: "cancel",
          data: {
            action: "cancel",
          },
        },
      ],
      backdropDismiss: false,
    });

    await actionSheet.present();

    const res = await actionSheet.onDidDismiss();
    console.log(res);
    if (res["data"]["action"] !== "cancel") {
      this.upload(res["data"]["action"] === "public");
    }
  }

  uploadVideo(isPublic: boolean, poster: string | undefined, handler: () => void) {
    const config = {
      onUploadProgress: (progressEvent: any) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.uploadPercentage = percentCompleted;
        console.log(percentCompleted);
      },
    };

    const form = new FormData();
    const filename = `video.${this.videoMime === "video/mp4" ? "mp4" : "webm"}`;
    form.append("file", new File(this.videoBlob, filename, { type: this.videoMime }));
    let params: { [id: string]: any } = {
      productId: this.productId,
      tempo: this.tempo,
      instrument: this.instrument,
      partNo: this.partNo,
      status: isPublic ? "Y" : "N",
      sync: Math.round(this.syncValue * 1000),
      volume: this.volumeValue,
      length: this.waveDuration ? Math.round(this.waveDuration) : Math.round(gws!.getDuration()),
    };
    console.log(this.waveDuration, gws!.getDuration());
    if (this.ensembleId > 0) {
      params["ensembleId"] = this.ensembleId;
    }
    if (poster) {
      params["poster"] = poster;
    }
    api
      .post("/recordings", form, params, config)
      .then((r) => {
        if (r && r.data) {
          console.log(r.data);
          handler();
          this.recordDone();
        }
      })
      .catch((e) => {
        console.log(e);
        handler();
      });
    console.log(form, params);
  }

  posterUrlToBlob(dataurl: string): Blob {
    var arr = dataurl.split(","),
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: "image/jpeg" });
  }

  async upload(isPublic: boolean) {
    console.log(isPublic, this.videoMime, this.productId);
    this.isUploding = true;
    console.log("poster", posterUrl);
    if (posterUrl) {
      const form = new FormData();
      form.append("file", new File([this.posterUrlToBlob(posterUrl)], "poster.jpg", { type: "image/jpeg" }));
      api
        .post("/assets", form)
        .then((r) => {
          console.log(r);
          if (r && r.data) {
            const poster = r.data["assetId"];
            this.uploadVideo(isPublic, poster, () => {
              this.isUploding = false;
            });
          } else {
            this.uploadVideo(isPublic, undefined, () => {
              this.isUploding = false;
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.uploadVideo(isPublic, undefined, () => {
            this.isUploding = false;
          });
        });
    } else {
      this.uploadVideo(isPublic, undefined, () => {
        this.isUploding = false;
      });
    }
  }

  async recordDone() {
    const alert = await alertController.create({
      cssClass: "custom-alert",
      message: "Your performance has been saved. you can watch it in MY.",
      buttons: [
        {
          text: "OK",
          handler: () => {
            const platforms = getPlatforms();
            if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
              this.playerCommand = "close";
            }
            this.recordState = RecordState.None;
            this.$router.go(-(window.history.length - 1));
            setTimeout(() => {
              this.ionRouter.replace("/tabs/my");
            }, 300);
          },
          cssClass: "alert-button-confirm",
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }

  postToChild(msg: any) {
    const score = document.querySelector("#sol-score") as any;
    if (score) {
      console.log("to mxml", msg);
      score.message = msg;
    }
  }

  postToHorizontalScore(msg: any) {
    const score = document.querySelector("#horizontal-score") as any;
    if (score) {
      console.log("to mxml", msg);
      score.message = msg;
    }
  }

  loadWaveform() {
    if (!this.videoUrl) {
      return;
    }
    let options: WaveSurferParams = {
      container: "#waveform",
      scrollParent: false,
      interact: false,
      waveColor: "#ffa932",
      cursorColor: "#EEEEEE",
    };
    const platforms = getPlatforms();
    if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
      options["backend"] = "MediaElement";
    }
    console.log(options);
    gws = WaveSurfer.create(options);
    // console.log("nnn video", this.videoUrl);
    gws.load(this.videoUrl);
    this.volumeValue = gws.getVolume() * 100;
    gws.on("pause", () => {
      // console.log("waveserver pause");
      if (this.fromSync) {
        this.fromSync = false;
      } else {
        const progress = (gws!.getCurrentTime() / this.duration) * 100;
        this.postToChild(`seek_${progress}`);
      }
    });
    gws.on("finish", () => {
      gws!.seekTo(0);
      gws!.skip(this.syncValue);
      this.postToChild("pause");
      this.postToChild("rewind");
      this.waveProgress = 0;
      this.updateRange("#wave-progress", this.waveProgress);
      this.waveCurrent = 0;
      this.playerCommand = "pause";
      setTimeout(() => {
        this.playerCommand = "rewind";
      }, 100);
      this.isWavePlaying = false;
    });
    gws.on("ready", () => {
      this.waveDuration = gws!.getDuration();
      gws!.skip(this.syncValue);
      console.log("wave ready", this, this.waveDuration);
    });
    gws.on("audioprocess", () => {
      this.waveCurrent = gws!.getCurrentTime();
      this.waveDuration = gws!.getDuration();
      this.waveProgress = (this.waveCurrent / this.waveDuration) * 100;
      this.updateRange("#wave-progress", this.waveProgress);
    });
    gws.on("seek", () => {
      // console.log("waversurfer seek", gws.getCurrentTime());
      this.waveCurrent = gws!.getCurrentTime();
    });
    this.postToChild("rewind");
    // this.postToChild("solo_sound_on");
    // if (this.recordedParts.length > 0) {
    //   this.postToChild("mute_" + this.recordedParts.join(","));
    // }
    this.playerCommand = "rewind";
    this.waveCurrent = 0;
    this.waveProgress = 0;
    this.updateRange("#wave-progress", this.waveProgress);
    console.log("load", gws);
  }

  onWavePlay() {
    if (this.isWavePlaying) {
      gws?.pause();
      this.playerCommand = "pause";
      if (!this.isJoin) {
        this.postToHorizontalScore("pause");
      }
    } else {
      console.log("requested", Date.now());
      if (!this.isJoin && this.isSynchronizing && gws) {
        console.log("p", this.waveProgress, (this.prebeatDuration / 1000 / gws.getDuration()) * 100, (this.duration / gws.getDuration()) * 100);
        if (this.waveProgress <= (this.prebeatDuration / 1000 / gws.getDuration()) * 100) {
          this.waveProgress = 0;
          gws.seekTo(0);
          this.postToHorizontalScore("play");
        } else if (this.waveProgress < (this.duration / gws.getDuration()) * 100) {
          this.postToHorizontalScore("play");
        }
      }
      gws?.play();
      this.playerCommand = "play";
    }
    this.isWavePlaying = !this.isWavePlaying;
  }

  onChangeWavePosition() {
    if (!this.isWavePlaying && gws) {
      let s = (this.waveProgress / 100) * gws.getDuration();
      if (this.isSynchronizing) {
        if (this.isJoin) {
          console.log("seek from wave", s);
          this.playerCommand = `seek_${s}`;
          gws.seekTo(this.waveProgress / 100);
        } else {
          s -= this.prebeatDuration / 1000;
          if (s < 0) {
            s = 0;
          }
          if (s > this.duration) {
            s = this.duration;
          }
          const p = (s / this.duration) * 100;
          console.log("seek from wave", p, s);
          this.postToHorizontalScore(`seek_${p}`);
        }
      } else {
        gws.seekTo(this.waveProgress / 100);
      }
    }
  }

  onSync() {
    if (this.isWavePlaying) {
      gws?.pause();
      this.playerCommand = "pause";
      this.isWavePlaying = false;
    }
    this.isSynchronizing = true;
    if (!this.isJoin) {
      this.loadHorizontalScore();
      this.postToHorizontalScore("rewind");
    }
    this.waveProgress = 0;
    gws?.seekTo(0);
    this.playerCommand = "rewind";
  }

  onSyncBack(value: number) {
    this.syncValue += value; //0.003;
    this.fromSync = true;
    gws?.skipBackward(-value);
    console.log("sync", this.syncValue, value);
  }

  onSyncForward(value: number) {
    this.syncValue += value; //0.003;
    this.fromSync = true;
    gws?.skipForward(value);
    console.log("sync", this.syncValue, value);
  }

  onChangeVolume() {
    console.log("volume", this.volumeValue);
    gws?.setVolume(this.volumeValue / 100);
  }

  // getPoster() {
  //   const preview = document.querySelector("#preview") as HTMLVideoElement;
  //   if (preview) {
  //     const canvas = document.createElement("canvas");
  //     canvas.width = 480;
  //     canvas.height = 640;
  //     const ctx = canvas.getContext("2d");
  //     ctx?.drawImage(preview, 0, 0, canvas.width, canvas.height);
  //     return canvas.toDataURL("image/jpeg");
  //   }
  //   return undefined;
  // }

  updateRange(nid: string, value: number) {
    const range = document.querySelector(nid) as HTMLElement;
    if (range) {
      range.style.background = `linear-gradient(to right, #fe7c2e 0%, #fe7c2e ${value}%, #262d32 ${value}%, #262d32 100%)`;
    }
  }

  generateVideoThumbnail = (url: string) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");
      video.setAttribute("playsinline", "true");
      video.autoplay = true;
      video.muted = true;
      video.src = url;
      console.log("thumbnail source", video.src);
      video.onloadeddata = () => {
        video.currentTime = 1.0;
      };
      video.addEventListener("seeked", () => {
        let ctx = canvas.getContext("2d");
        let min = Math.min(video.videoWidth, video.videoHeight);
        canvas.width = min;
        canvas.height = min;
        ctx?.drawImage(video, (video.videoWidth - min) / 2, (video.videoHeight - min) / 2, min, min, 0, 0, min, min);
        video.pause();
        console.log("thumbnail loaded", canvas, ctx);
        return resolve(canvas.toDataURL("image/jpeg"));
      });
    });
  };

  loadScore() {
    const loadTimer = setInterval(() => {
      const score = this.$el.querySelector("#sol-score");
      if (score) {
        console.log("event");
        score.addEventListener("mxmlMessage", this.onMessage);
        score.addEventListener("audioContext", (e: any) => {
          console.log(e.detail);
          this.audioContext = e.detail;
        });
        clearInterval(loadTimer);
        this.postProductVist();
      }
    }, 50);
  }

  loadHorizontalScore() {
    const loadTimer = setInterval(() => {
      const score = document.querySelector("#horizontal-score");
      if (score) {
        console.log("event");
        score.addEventListener("mxmlMessage", this.onHorizontalMessage);
        // score.addEventListener("audioContext", (e: any) => {
        //   console.log(e.detail);
        //   this.audioContext = e.detail;
        // });
        clearInterval(loadTimer);
      }
    }, 50);
  }

  postProductVist() {
    const params: { [id: string]: any } = {
      productId: this.productId,
    };
    if (api.user.userId) {
      params["userId"] = api.user.userId;
    }
    api.post("/apps/product_visit", null, params).then((r) => {
      console.log("product_visit", r);
    });
  }

  postProductPlay() {
    const params: { [id: string]: any } = {
      productId: this.productId,
    };
    if (api.user.userId) {
      params["userId"] = api.user.userId;
    }
    api.post("/apps/product_play", null, params).then((r) => {
      console.log("product_play", r);
    });
  }

  getLandscapeRecordBtnRight(): string {
    const playerWidth = api.playerHeight - 32;
    const scoreWidth = window.innerWidth - playerWidth;
    const buttonWidth = 48;
    return `${scoreWidth / 2 - buttonWidth / 2}px`;
  }

  lockCurrentOrientation() {
    ScreenOrientation.orientation().then((r: ScreenOrientationResult) => {
      let type: any = r.type;
      console.log("org", type);
      const platforms = getPlatforms();
      if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
        type = this.currentOrientation;
        if (type === "landscape-primary") {
          type = "landscape-secondary";
        } else if (r.type === "landscape-secondary") {
          type = "landscape-primary";
        }
      }
      console.log("screen", "lock", type);
      ScreenOrientation.lock({ orientation: type });
    });
  }

  unlockScreenOrientation() {
    console.log("screen", "unlock");
    ScreenOrientation.unlock();
  }

  isIPad() {
    const platforms = getPlatforms();
    console.log("platforms", platforms);
    return platforms.indexOf("ipad") != -1;
  }

  async showUseHeadphone() {
    const alert = await alertController.create({
      cssClass: "custom-alert",
      message: "Check the Headphones<br><br>Using headphones can eliminate noise and record the best sound.",
      buttons: [
        {
          text: "OK",
          cssClass: "alert-button-confirm",
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
  }

  async showMicUsage(callback: () => void) {
    const alert = await alertController.create({
      cssClass: "custom-alert",
      message: "Microphone Restricted<br><br>We will use the basic microphones on your phone for the best recording.",
      buttons: [
        {
          text: "OK",
          cssClass: "alert-button-confirm",
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
    await alert.onDidDismiss();
    callback();
  }
}
