
import { Options, Vue } from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import { api } from "@/api/api";
import "video.js/dist/video-js.css";
import videojs, { VideoJsPlayer } from "video.js";
import { playCircle, playBack, playForward, pauseCircle, expandSharp } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";

@Options({
  name: "VideoPlayer",
  components: {
    IonIcon,
  },
})
export default class VideoPlayer extends Vue {
  @Prop({ type: Number })
  public recordingId!: number;

  @Prop({ type: String })
  public video!: string;

  @Prop({ type: String })
  public poster!: string;

  @Prop({ type: String })
  command!: string;

  @Prop({ type: Boolean })
  autoplay!: boolean;

  @Prop({ type: Boolean })
  isVertical!: boolean;

  @Ref("player") readonly playerRef!: HTMLDivElement;

  api = api;

  playCircle = playCircle;
  pauseCircle = pauseCircle;
  playBack = playBack;
  playForward = playForward;
  expandSharp = expandSharp;

  playerWidth = 0;
  playerHeight = 0;
  playerLeft = 0;
  playerTop = 0;
  videoWidth = 0;
  prefix = "0";
  orgPlayerWidth = 0;

  player!: VideoJsPlayer;
  videoReady = false;
  isPlaying = false;
  isShowControl = false;
  controlTimeoutId = 0;
  duration = 0;
  current = 0;
  progress = 0;
  isEnded = false;
  isSeeking = false;

  mounted() {
    this.prefix = (Math.random() + 1).toString(36).substring(7);
    this.load();
  }

  @Watch("command")
  onChangeCommand() {
    if (this.command === "play") {
      if (!this.isPlaying) {
        this.onPlay();
      }
    } else if (this.command === "pause") {
      if (this.isPlaying) {
        this.onPlay();
      }
    } else if (this.command === "rewind") {
      if (this.isPlaying) {
        this.onPlay();
      }
      this.current = 0;
      this.seek(this.current);
    }
  }

  @Watch("isVertical")
  onChangeIsVertical() {
    setTimeout(() => {
      this.load();
    }, 500);
  }

  load() {
    this.playerWidth = this.playerRef.clientWidth;
    this.videoWidth = this.playerRef.clientWidth;
    this.playerHeight = Math.min(this.playerWidth / 2, api.playerHeight);
    this.playerLeft = this.playerRef.getClientRects()[0].left;
    this.playerTop = this.playerRef.getClientRects()[0].top;
    console.log(this.playerWidth, this.videoWidth, this.prefix);
    console.log(this.playerLeft, this.playerTop, this.playerWidth, this.playerHeight);
    setTimeout(() => {
      this.updateVideo();
    }, 300);
  }

  updateVideo() {
    if (this.player) {
      this.player.width(this.playerWidth);
      console.log(this.playerWidth);
      return;
    }
    const v = videojs(`rec-${this.prefix}`, {
      playsinline: true,
      poster: this.poster,
      width: this.playerWidth,
      height: this.playerHeight,
    });
    v.src({ type: "video/mp4", src: this.video });
    this.player = v;
    v.on("fullscreenchange", this.onChangeFullscreen);
    const readyCheck = setInterval(() => {
      if (this.player.readyState() >= 4) {
        this.duration = this.player.duration();
        this.player.on("timeupdate", () => {
          if (!this.isSeeking) {
            this.current = this.player.currentTime();
            this.progress = Math.round((this.current / this.duration) * 1000) / 10;
          }
        });
        this.player.on("ended", () => {
          this.isPlaying = false;
          this.isEnded = true;
          this.showControl(true, false);
        });
        console.log("total", this.duration);
        if (this.autoplay) {
          setTimeout(() => {
            this.onPlay();
          });
        } else {
          this.showControl(true, true);
        }
        clearInterval(readyCheck);
      }
    }, 500);
  }

  onPlay(event: Event | null = null) {
    if (this.isPlaying) {
      this.player.pause();
    } else {
      if (this.isEnded) {
        this.seek(0);
        this.isEnded = false;
      }
      this.player.play();
    }
    this.isPlaying = !this.isPlaying;
    if (!this.isPlaying) {
      this.showControl(true, false);
    } else {
      this.showControl(true, true);
    }
    const params: { [id: string]: any } = {
      recordingId: this.recordingId,
    };
    if (api.user.userId) {
      params["userId"] = api.user.userId;
    }
    api.post("/apps/recording_play", null, params);

    if (event) {
      event.stopPropagation();
    }
  }

  showControl(isShow: boolean, delayed: boolean) {
    // console.log(this.autoplay);
    // if (!this.autoplay) {
    //   this.isShowControl = false;
    //   return;
    // }
    if (this.controlTimeoutId) {
      clearTimeout(this.controlTimeoutId);
      this.controlTimeoutId = 0;
    }
    if (isShow) {
      this.isShowControl = true;
      if (this.isPlaying && delayed) {
        this.controlTimeoutId = setTimeout(() => {
          this.isShowControl = false;
          this.controlTimeoutId = 0;
        }, 2000);
      }
    } else {
      this.isShowControl = false;
    }
  }

  onClickPlayer() {
    if (this.isPlaying) {
      this.showControl(true, true);
    } else {
      this.showControl(true, false);
    }
  }

  seek(to: number) {
    if (to < 0) {
      to = 0;
    }

    if (this.isPlaying && !this.player.paused()) {
      this.player.pause();
    }
    if (to < this.player.duration()) {
      this.player.currentTime(to);
      if (this.isPlaying && this.player.paused()) {
        this.player.play();
      }
    } else {
      this.player.currentTime(this.player.duration() - 0.1);
      this.player.pause();
    }
  }
  onSeek() {
    this.current = (this.duration * this.progress) / 100;
    this.seek(this.current);
  }

  onSeekBack() {
    this.seek(this.current - 10);
  }

  onSeekForward() {
    this.seek(this.current + 10);
  }

  onFullScreen() {
    this.player.requestFullscreen();
  }

  onChangeFullscreen() {
    if (this.player.isFullscreen()) {
      this.player.controls(true);
    } else {
      this.player.controls(false);
    }
  }
}
