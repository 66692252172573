import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4eed0fd1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-left":"20px","width":"calc(100% - 40px)","margin-top":"30px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { text: "" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Bug Report")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, { style: {"margin-top":"5px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "title" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Please let me know if there are any ideas or bugs while using the SOLCHESTRA app.")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_textarea, {
                    class: "content",
                    placeholder: "Type something here",
                    "auto-grow": true,
                    modelValue: _ctx.text,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ion_button, {
                  expand: "block",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onReport()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Report")
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}