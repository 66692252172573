
import { Options, Vue } from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import { api, Recording } from "@/api/api";
import "video.js/dist/video-js.css";
import videojs, { VideoJsPlayer } from "video.js";
import { playCircle, playBack, playForward, pauseCircle, expandSharp } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";
import { getPlatforms } from "@ionic/vue";

interface VideoPart {
  video: VideoJsPlayer;
  sync: number;
  volume: number;
}

@Options({
  name: "Player",
  components: {
    IonIcon,
  },
})
export default class Player extends Vue {
  @Prop({ type: Number })
  public ensembleId!: number;

  @Prop({ type: Boolean })
  public isRecording!: boolean;

  @Prop({ type: Boolean })
  includePrivate!: boolean;

  @Prop({ type: String })
  command!: string;

  @Prop({ type: Boolean })
  autoplay!: boolean;

  @Prop({ type: Number })
  ignorePart!: number;

  @Prop({ type: Boolean })
  isVertical!: boolean;

  @Ref("player") readonly playerRef!: HTMLDivElement[];

  api = api;

  playCircle = playCircle;
  pauseCircle = pauseCircle;
  playBack = playBack;
  playForward = playForward;
  expandSharp = expandSharp;

  playerWidth = 0;
  playerHeight = 0;
  playerLeft = 0;
  playerTop = 0;
  videoWidth = 0;
  videoHeight = 0;
  prefix = "";
  orgPlayerWidth = 0;

  ensemble: Recording[] = [];
  videos: VideoPart[] = [];
  videoReady = false;
  isPlaying = false;
  isShowControl = false;
  controlTimeoutId = 0;
  longestVideoIdx = 0;
  duration = 0;
  current = 0;
  progress = 0;
  isEnded = false;
  isSeeking = false;

  nativeLoaded = false;

  mounted() {
    this.load();
    setTimeout(() => {
      if (this.playerRef) {
        this.playerRef[0].addEventListener("fullscreenchange", this.onChangeFullscreen);
      }
    }, 1000);
  }

  @Watch("ensembleId")
  onChangeEnsembleId() {
    this.load();
  }

  @Watch("includePrivate")
  onChangeIncludePrivate() {
    this.load();
  }

  @Watch("command")
  onChangeCommand() {
    const platforms = getPlatforms();
    if (this.command === "play") {
      if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
        api.commandNativePlayer("play");
        return;
      }
      if (!this.isPlaying) {
        this.onPlay();
      }
    } else if (this.command === "pause") {
      if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
        api.commandNativePlayer("pause");
        return;
      }
      if (this.isPlaying) {
        this.onPlay();
      }
    } else if (this.command === "rewind") {
      if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
        api.commandNativePlayer("rewind");
        return;
      }
      if (this.isPlaying) {
        this.onPlay();
      }
      this.current = 0;
      this.seek(this.current);
    } else if (this.command.startsWith("seek")) {
      if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
        api.commandNativePlayer(this.command);
        return;
      }
      const sec = Number(this.command.split("_")[1]);
      console.log("seek player", sec);
      this.seek(sec);
    } else if (this.command === "load") {
      if (!this.nativeLoaded) {
        this.load();
      }
    } else if (this.command === "close") {
      api.closeNativePlayer(this.ensembleId);
      this.nativeLoaded = false;
    }
  }

  @Watch("isVertical")
  onChangeIsVertical() {
    setTimeout(() => {
      if (this.isVertical) {
        this.playerWidth = this.playerRef[0].clientWidth;
        this.playerHeight = this.playerRef[0].clientHeight;
        this.videoWidth = this.playerWidth;
        this.videoHeight = this.playerHeight / this.ensemble[0].users.length;
        this.playerLeft = this.playerRef[0].getClientRects()[0].left;
        this.playerTop = this.playerRef[0].getClientRects()[0].top;
      } else {
        this.playerWidth = this.playerRef[0].clientWidth;
        this.videoWidth = this.playerRef[0].clientWidth / this.ensemble[0].users.length;
        this.playerHeight = api.playerHeight;
        this.videoHeight = this.playerHeight;
        this.playerLeft = this.playerRef[0].getClientRects()[0].left;
        this.playerTop = this.playerRef[0].getClientRects()[0].top;
      }
      const platforms = getPlatforms();
      if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
        api.commandNativePlayer(`orientation_${this.isRecording && this.isVertical ? 1 : 0},${Math.round(this.playerLeft)},${Math.round(this.playerTop)},${Math.round(this.playerWidth)},${Math.round(this.playerHeight)}`);
      }
      console.log(this.isVertical, this.videoWidth, this.videoHeight, this.prefix, api.playerHeight);
      console.log(this.playerLeft, this.playerTop, this.playerWidth, this.playerHeight);
      // this.updateVideo();
    }, 300);
  }

  load() {
    console.log("playr - load");
    const params: { [id: string]: any } = {};
    if (this.includePrivate) {
      params["includePrivate"] = this.includePrivate;
    }
    api.get(`/apps/recordings/${this.ensembleId}`, params).then((r) => {
      if (r && r.data) {
        this.ensemble = [r.data["recording"]];
        this.ensemble[0].users = this.ensemble[0].users.filter((u) => u.no != this.ignorePart);
        setTimeout(() => {
          if (this.isVertical) {
            this.playerWidth = this.playerRef[0].clientWidth;
            this.playerHeight = this.playerRef[0].clientHeight;
            this.videoWidth = this.playerWidth;
            this.videoHeight = this.playerHeight / this.ensemble[0].users.length;
            this.playerLeft = this.playerRef[0].getClientRects()[0].left;
            this.playerTop = this.playerRef[0].getClientRects()[0].top;
          } else {
            this.playerWidth = this.playerRef[0].clientWidth;
            this.videoWidth = this.playerRef[0].clientWidth / this.ensemble[0].users.length;
            this.playerHeight = api.playerHeight;
            this.videoHeight = this.playerHeight;
            this.playerLeft = this.playerRef[0].getClientRects()[0].left;
            this.playerTop = this.playerRef[0].getClientRects()[0].top;
          }
          this.prefix = (Math.random() + 1).toString(36).substring(7);
          console.log(this.isVertical, this.playerWidth, this.videoWidth, this.prefix, api.playerHeight);
          console.log(this.playerLeft, this.playerTop, this.playerWidth, this.playerHeight);
          const platforms = getPlatforms();
          if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
            this.nativeLoaded = true;
            setTimeout(() => {
              this.playerTop = this.playerRef[0].getClientRects()[0].top;
              console.log("top", this.playerTop);
              console.log("autoplay", this.autoplay);
              if (this.nativeLoaded) {
                api.openNativePlayer(this.ensemble[0].ensembleId, 0, this.playerTop, this.playerWidth, this.playerHeight, this.autoplay ? this.autoplay : false, this.isRecording && this.isVertical ? true : false, this.ignorePart ? this.ignorePart : 0);
              }
            }, 1000);
          } else {
            setTimeout(() => {
              this.updateVideo();
            });
          }
        });
        console.log(this.ensemble);
      }
    });
  }

  updateVideo() {
    this.videos = [];
    for (const u of this.ensemble[0].users) {
      const v = videojs(`rec-${this.prefix}-${u.recordingId}`, {
        playsinline: true,
        poster: api.getAssetUrl(u.poster),
      });
      v.src({ type: u.type, src: api.getAssetUrl(u.assetId, true) });
      const part: VideoPart = {
        video: v,
        sync: u.sync,
        volume: u.volume,
      };
      this.videos.push(part);
    }
    this.videoReady = false;
    const readyCheck = setInterval(() => {
      let allDone = true;
      for (const v of this.videos) {
        if (v.video.readyState() < 4) {
          allDone = false;
          break;
        }
      }
      if (allDone) {
        this.videoReady = true;
        this.duration = 0;
        console.log("all ready");
        for (const [i, v] of this.videos.entries()) {
          v.video.currentTime(v.sync / 1000);
          console.log("sync", v.sync / 1000);
          v.video.volume(v.volume / 100);
          if (this.duration < v.video.duration() - v.sync / 1000) {
            this.duration = v.video.duration() - v.sync / 1000;
            this.longestVideoIdx = i;
          }
        }
        this.videos[this.longestVideoIdx].video.on("timeupdate", () => {
          if (!this.isSeeking) {
            this.current = this.videos[this.longestVideoIdx].video.currentTime() - this.videos[this.longestVideoIdx].sync / 1000;
            this.progress = Math.round((this.current / this.duration) * 1000) / 10;
          }
        });
        this.videos[this.longestVideoIdx].video.on("ended", () => {
          this.isPlaying = false;
          this.isEnded = true;
          this.showControl(true, false);
        });
        console.log("total", this.longestVideoIdx, this.duration);
        if (this.autoplay) {
          setTimeout(() => {
            this.onPlay();
          });
        }
        clearInterval(readyCheck);
      }
    }, 500);
  }

  onPlay(event: Event | null = null) {
    for (const v of this.videos) {
      if (this.isPlaying) {
        v.video.pause();
      } else {
        if (this.isEnded) {
          this.seek(0);
          this.isEnded = false;
        }
        v.video.play();
      }
    }
    this.isPlaying = !this.isPlaying;
    if (!this.isPlaying) {
      this.showControl(true, false);
    } else {
      this.showControl(true, true);
    }

    if (this.ensemble.length > 0 && this.isPlaying) {
      for (const u of this.ensemble[0].users) {
        const params: { [id: string]: any } = {
          recordingId: u.recordingId,
        };
        if (api.user.userId) {
          params["userId"] = api.user.userId;
        }
        api.post("/apps/recording_play", null, params).then((r) => {
          if (r && r.data) {
            this.ensemble[0].visits += 1;
          }
        });
      }
    }
    if (event) {
      event.stopPropagation();
    }
  }

  showControl(isShow: boolean, delayed: boolean) {
    console.log(this.autoplay);
    if (!this.autoplay) {
      this.isShowControl = false;
      return;
    }
    if (this.controlTimeoutId) {
      clearTimeout(this.controlTimeoutId);
      this.controlTimeoutId = 0;
    }
    if (isShow) {
      this.isShowControl = true;
      if (this.isPlaying && delayed) {
        this.controlTimeoutId = setTimeout(() => {
          this.isShowControl = false;
          this.controlTimeoutId = 0;
        }, 2000);
      }
    } else {
      this.isShowControl = false;
    }
  }

  onClickPlayer() {
    if (this.isPlaying) {
      this.showControl(true, true);
    } else {
      this.showControl(true, false);
    }
  }

  seek(to: number) {
    if (to < 0) {
      to = 0;
    }
    for (const v of this.videos) {
      if (this.isPlaying && !v.video.paused()) {
        v.video.pause();
      }
      if (to < v.video.duration()) {
        v.video.currentTime(to + v.sync / 1000);
        if (this.isPlaying && v.video.paused()) {
          v.video.play();
        }
      } else {
        v.video.currentTime(v.video.duration() - 0.1);
        v.video.pause();
      }
    }
  }
  onSeek() {
    this.current = (this.duration * this.progress) / 100;
    this.seek(this.current);
  }

  onSeekBack() {
    this.seek(this.current - 10);
  }

  onSeekForward() {
    this.seek(this.current + 10);
  }

  onFullScreen() {
    if (!document.fullscreenElement) {
      this.playerRef[0].requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  onChangeFullscreen() {
    if (document.fullscreenElement) {
      this.orgPlayerWidth = this.playerWidth;
    }
    setTimeout(() => {
      console.log("change full screen", document.fullscreenElement, this.playerRef[0].clientWidth, this.playerRef[0].clientHeight, api.playerHeight);
      this.playerWidth = document.fullscreenElement ? this.playerRef[0].clientWidth : this.orgPlayerWidth;
      this.videoWidth = this.playerWidth / this.ensemble[0].users.length;
      this.playerHeight = document.fullscreenElement ? this.playerRef[0].clientHeight : api.playerHeight;
      for (const u of this.ensemble[0].users) {
        const div = document.querySelector(`#rec-${this.prefix}-${u.recordingId}`) as HTMLElement;
        if (div) {
          div.style.width = `${this.videoWidth}px`;
          div.style.height = `${this.playerHeight}px`;
          const video = div.querySelector("video") as HTMLElement;
          if (video) {
            video.style.width = `${this.videoWidth}px`;
            video.style.height = `${this.playerHeight}px`;
            video.style.minHeight = `${this.playerHeight}px`;
            console.log("width", `${this.videoWidth}px`, "height", `${this.playerHeight}px`);
          }
        }
      }
    }, 100);
  }
}
