
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonLabel, IonInput, IonButton, IonGrid, IonRow, IonCol } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";
import { useIonRouter } from "@ionic/vue";

@Options({
  name: "Login",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
  },
})
export default class Login extends Vue {
  api = api;
  email = "";
  password = "";
  isError = false;
  ionRouter = useIonRouter();

  login(): void {
    // console.log("loging", this.email, this.password);
    const data = new FormData();
    data.append("username", this.email);
    data.append("password", this.password);
    api
      .post("/token", data)
      .then((r) => {
        if (r && r.data) {
          const auth = `${r.data.token_type} ${r.data.access_token}`;
          api.setAuth(auth);
          api.checkMe(() => {
            console.log("check me");
            api.needUpdate = true;
            this.$router.go(-1);
          });
        }
      })
      .catch(() => {
        this.isError = true;
      });
  }

  onResetPasswd() {
    this.ionRouter.push("/forgot");
  }

  onNewUser() {
    this.ionRouter.push("/newuser");
  }
}
