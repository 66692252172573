import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-747be0a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "video-player",
  ref: "player"
}
const _hoisted_2 = { class: "bar" }
const _hoisted_3 = { class: "progress-bar" }
const _hoisted_4 = { class: "time" }
const _hoisted_5 = { class: "buttons" }
const _hoisted_6 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.playerWidth != 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: {"display":"flex"},
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onClickPlayer())),
          onTouchstart: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onClickPlayer()))
        }, [
          _createElementVNode("div", {
            class: "controls",
            style: _normalizeStyle({ width: _ctx.playerWidth + 'px', height: _ctx.playerHeight + 'px', visibility: _ctx.isShowControl ? 'visible' : 'hidden' })
          }, [
            _createVNode(_component_ion_icon, {
              class: "play",
              icon: _ctx.isPlaying ? _ctx.pauseCircle : _ctx.playCircle,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPlay($event)))
            }, null, 8, ["icon"]),
            _createVNode(_component_ion_icon, {
              class: "play-back",
              icon: _ctx.playBack,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSeekBack()))
            }, null, 8, ["icon"]),
            _createVNode(_component_ion_icon, {
              class: "play-forward",
              icon: _ctx.playForward,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSeekForward()))
            }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _withDirectives(_createElementVNode("input", {
                  type: "range",
                  class: "progress-range",
                  min: "0",
                  max: "100",
                  step: "0.01",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.progress) = $event)),
                  orient: "horizontal",
                  onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSeek()))
                }, null, 544), [
                  [_vModelText, _ctx.progress]
                ])
              ]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.api.getDuration(_ctx.current)) + "/" + _toDisplayString(_ctx.api.getDuration(_ctx.duration)), 1),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_ion_icon, {
                  class: "expand",
                  icon: _ctx.expandSharp,
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onFullScreen()))
                }, null, 8, ["icon"])
              ])
            ])
          ], 4),
          _createElementVNode("video", {
            class: "video-js",
            id: 'rec-' + _ctx.prefix
          }, null, 8, _hoisted_6)
        ], 32))
      : _createCommentVNode("", true)
  ], 512))
}