
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
})
export default class App extends Vue {}
