
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";
import Songs from "@/components/Songs.vue";

@Options({
  name: "Genre",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    Songs,
  },
})
export default class Genre extends Vue {
  api = api;
  genreSubId: number | null = null;
  genreName: string | null = null;

  created() {
    if (this.$route.params && this.$route.params["id"]) {
      this.genreSubId = Number(this.$route.params["id"]);
    }
    if (this.$route.params && this.$route.params["name"]) {
      this.genreName = String(this.$route.params["name"]);
    }
  }
}
