
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonAvatar, useIonRouter, IonTextarea, IonSegment, IonSegmentButton, alertController } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { ellipsisHorizontal, ellipsisVertical, createOutline, checkmarkCircleOutline, close } from "ionicons/icons";
import { api, User } from "@/api/api";
import StartedRecordings from "@/components/StartedRecordings.vue";

@Options({
  name: "My",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonAvatar,
    StartedRecordings,
    IonTextarea,
    IonSegment,
    IonSegmentButton,
  },
})
export default class My extends Vue {
  api = api;
  ionRouter = useIonRouter();
  ellipsisHorizontal = ellipsisHorizontal;
  ellipsisVertical = ellipsisVertical;
  createOutline = createOutline;
  close = close;
  checkmarkCircleOutline = checkmarkCircleOutline;
  user: User | null = null;
  isEdit = false;
  newImage = "";
  updateCnt = 1;
  userId!: number;

  segment = "started";

  created() {
    console.log(this.$route.params);
    if (this.$route.params && this.$route.params["userId"]) {
      this.userId = Number(this.$route.params["userId"]);
    }
  }

  mounted() {
    console.log("my", this.userId);
    this.api.needUpdate = true;
    this.ionViewWillEnter();
  }

  ionViewWillEnter() {
    console.log("view");
    this.updateCnt += 1;
    console.log(this.userId, api.user, this.updateCnt, window.location.pathname);

    if (window.location.pathname.indexOf("/tabs/") != -1 && !api.user.userId) {
      this.ionRouter.push("/login");
    } else {
      if (api.needUpdate) {
        api.get(`/apps/user/${this.userId ? this.userId : api.user.userId}`).then((r) => {
          if (r && r.data) {
            this.user = r.data;
            if (this.user && !this.user.description) {
              this.user.description = "hello!";
            }
            console.log(this.user);
          }
        });
        api.needUpdate = false;
      }
    }
  }

  onMore(): void {
    this.ionRouter.push("/setting");
  }

  onEdit(): void {
    this.isEdit = !this.isEdit;
    if (!this.isEdit && this.user) {
      const data: { [id: string]: string } = {
        description: this.user.description,
      };
      if (this.newImage !== "") {
        data["image"] = this.newImage;
      }
      api.put(`/apps/user/${api.user.userId}`, data).then((r) => {
        console.log(r);
      });
    }
  }

  onDeleteAvatar() {
    this.confirmDelete();
  }

  async confirmDelete() {
    const alert = await alertController.create({
      cssClass: "custom-alert",
      header: "Delete",
      message: "Are you sure you want to delete the existing picture?",
      buttons: [
        {
          text: "Delete",
          role: "delete",
          cssClass: "alert-button-default",
        },
        {
          text: "Cancel",
          role: "continue",
          cssClass: "alert-button-confirm",
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    if (role === "delete") {
      if (this.user) {
        this.user.image = undefined;
      }
      this.newImage = "NULL";
    }
    console.log(role);
  }

  onChangeAvatar() {
    if (this.isEdit) {
      let element: HTMLElement = document.querySelector('input[type="file"]') as HTMLElement;
      element.click();
    }
  }

  importFile(event: any) {
    if (event.target.files.length == 0) {
      console.log("No file selected!");
      return;
    } else if (event.target.files.length == 1) {
      let file = event.target.files[0];
      const form = new FormData();
      form.append("file", file);
      api.post("/assets", form).then((r) => {
        if (r && r.data) {
          this.newImage = r.data["assetId"];
          if (this.user) {
            this.user.image = this.newImage;
          }
        }
      });
    }
  }

  onClose() {
    this.$router.back();
  }

  changeSegment(sel: any) {
    this.segment = sel.detail.value;
    console.log(this.segment);
  }
}
