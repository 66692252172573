import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_tabs, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_router_outlet),
              _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_tab_button, {
                    tab: "play",
                    href: "/tabs/play"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        src: `${_ctx.api.publicPath}/assets/images/ico-play.svg`
                      }, null, 8, ["src"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("play")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_tab_button, {
                    tab: "hall",
                    href: "/tabs/hall"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        src: `${_ctx.api.publicPath}/assets/images/ico-hall.svg`
                      }, null, 8, ["src"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("hall")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_tab_button, {
                    tab: "search",
                    href: "/tabs/search"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        src: `${_ctx.api.publicPath}/assets/images/ico-search.svg`
                      }, null, 8, ["src"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("search")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_tab_button, {
                    tab: "my",
                    href: "/tabs/my"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        src: `${_ctx.api.publicPath}/assets/images/ico-user.svg`
                      }, null, 8, ["src"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("my")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}