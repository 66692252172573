
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonButton, IonList, IonItem, IonLabel, useIonRouter } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";

@Options({
  name: "Copyright",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
  },
})
export default class Copyright extends Vue {
  api = api;
  ionRouter = useIonRouter();
}
