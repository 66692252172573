
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, useIonRouter, IonLabel, IonNote } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";
import Recordings from "@/components/Recordings.vue";

@Options({
  name: "JoinList",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonLabel,
    IonNote,
    Recordings,
  },
})
export default class JoinList extends Vue {
  api = api;
  ionRouter = useIonRouter();
  titleId: number | null = null;
  title: string | null = null;
  artistId: number | null = null;
  artist: string | null = null;
  ensembleSubId: number | null = null;

  created() {
    if (this.$route.params && this.$route.params["titleId"]) {
      this.titleId = Number(this.$route.params["titleId"]);
    }
    if (this.$route.params && this.$route.params["title"]) {
      this.title = String(this.$route.params["title"]);
    }
    if (this.$route.params && this.$route.params["artistId"]) {
      this.artistId = Number(this.$route.params["artistId"]);
    }
    if (this.$route.params && this.$route.params["artist"]) {
      this.artist = String(this.$route.params["artist"]);
    }
    if (this.$route.params && this.$route.params["ensembleSubId"]) {
      this.ensembleSubId = Number(this.$route.params["ensembleSubId"]);
    }
  }
}
