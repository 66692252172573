
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonItem, IonLabel, IonNote, IonIcon, useIonRouter, IonButton } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api, Category } from "@/api/api";
import StartedRecordings from "@/components/StartedRecordings.vue";

@Options({
  name: "Started",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonItem,
    IonLabel,
    IonNote,
    IonIcon,
    StartedRecordings,
    IonButton,
  },
})
export default class Started extends Vue {
  api = api;
  ionRouter = useIonRouter();

  titleId: number | null = null;
  artistId: number | null = null;
  title: string | null = null;
  artist: string | null = null;
  ensembleSubId: number | null = null;

  created() {
    if (this.$route.params && this.$route.params["titleId"]) {
      this.titleId = Number(this.$route.params["titleId"]);
    }
    if (this.$route.params && this.$route.params["artistId"]) {
      this.artistId = Number(this.$route.params["artistId"]);
    }
    if (this.$route.params && this.$route.params["title"]) {
      this.title = String(this.$route.params["title"]);
    }
    if (this.$route.params && this.$route.params["artist"]) {
      this.artist = String(this.$route.params["artist"]);
    }
    if (this.$route.params && this.$route.params["ensembleSubId"]) {
      this.ensembleSubId = Number(this.$route.params["ensembleSubId"]);
    }
    console.log(this.titleId, this.artistId, this.ensembleSubId);
  }

  mounted() {
    console.log("mounted");
  }

  onStart(isRecording: boolean) {
    if (!api.user.userId) {
      this.ionRouter.push("/login");
      return;
    }
    if (this.ensembleSubId) {
      const params: { [id: string]: any } = {
        titleId: this.titleId,
        artistId: this.artistId,
        ensembleSubId: this.ensembleSubId,
      };
      api.get(`/apps/instrument`, params).then((r) => {
        if (r && r.data) {
          const categories: Category[] = r.data["categories"];
          if (categories.length > 0) {
            console.log(categories);
            this.ionRouter.push(`/start/instrument/${categories[0].productId}/0/${this.title}/${this.artist}/${isRecording}`);
          }
        }
      });
    } else {
      this.ionRouter.push(`/start/ensemble/${this.titleId}/${this.artistId}/${this.title}/${this.artist}/${isRecording}`);
    }
  }
}
