
import { IonContent, IonPage, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonRouterOutlet } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";

@Options({
  name: "Tabs",
  components: {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonContent,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
})
export default class Tabs extends Vue {
  api = api;
}
