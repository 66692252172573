
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonAccordion, IonAccordionGroup, useIonRouter, IonLabel, IonItem, IonNote, IonIcon } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api, Category, CategorySub } from "@/api/api";

@Options({
  name: "SelectEnsemble",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonAccordion,
    IonAccordionGroup,
    IonLabel,
    IonItem,
    IonNote,
    IonIcon,
  },
})
export default class SelectEnsemble extends Vue {
  api = api;
  ionRouter = useIonRouter();
  titleId: number | null = null;
  title: string | null = null;
  artistId: number | null = null;
  artist: string | null = null;
  isRecording: boolean | null = null;
  mains: string[] = [];
  subs: { [id: string]: CategorySub[] } = {};

  created() {
    if (this.$route.params && this.$route.params["titleId"]) {
      this.titleId = Number(this.$route.params["titleId"]);
    }
    if (this.$route.params && this.$route.params["title"]) {
      this.title = String(this.$route.params["title"]);
    }
    if (this.$route.params && this.$route.params["artistId"]) {
      this.artistId = Number(this.$route.params["artistId"]);
    }
    if (this.$route.params && this.$route.params["artist"]) {
      this.artist = String(this.$route.params["artist"]);
    }
    if (this.$route.params && this.$route.params["isRecording"]) {
      this.isRecording = this.$route.params["isRecording"] === "true" ? true : false;
    }
  }

  mounted(): void {
    const params: { [id: string]: any } = {
      titleId: this.titleId,
      artistId: this.artistId,
    };
    // SHOW_ALL_LIST
    // if (api.user && api.user.instruments) {
    //   params["instruments"] = api.user.instruments;
    // }
    api.get(`/apps/instrument`, params).then((r) => {
      if (r && r.data) {
        const categories: Category[] = r.data["categories"];
        this.mains = [];
        this.subs = {};
        for (const c of categories) {
          if (this.subs[c.main]) {
            this.subs[c.main].push({ subId: c.subId, name: c.sub, productId: c.productId });
          } else {
            this.mains.push(c.main);
            this.subs[c.main] = [{ subId: c.subId, name: c.sub, productId: c.productId }];
          }
        }
      }
    });
  }

  onSub(sub: CategorySub) {
    this.ionRouter.push(`/start/instrument/${sub.productId}/0/${this.title}/${this.artist}/${this.isRecording}`);
    console.log(sub.productId, this.isRecording);
  }
}
