
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonButton, IonList, IonItem, IonLabel, useIonRouter } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api, Article } from "@/api/api";

@Options({
  name: "Notice",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
  },
})
export default class Notice extends Vue {
  api = api;
  ionRouter = useIonRouter();
  articles: Article[] = [];

  mounted() {
    api.get("/articles", { boardId: 1 }).then((r) => {
      if (r && r.data) {
        this.articles = r.data["articles"];
        console.log(this.articles);
      }
    });
  }

  onViewNotice(articleId: number) {
    this.ionRouter.push(`/notice/${articleId}`);
  }
}
