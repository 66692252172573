
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonLabel, IonNote, IonIcon, IonAvatar, IonButton, useIonRouter, actionSheetController, alertController, toastController } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api, Recording } from "@/api/api";
import { play, heart, heartOutline, createOutline, shareSocial, close } from "ionicons/icons";
import Player from "@/components/Player.vue";
import { getPlatforms } from "@ionic/vue";
import { ScreenOrientation, ScreenOrientationResult } from "@capacitor/screen-orientation";
// import { App } from "@capacitor/app";
// declare var Kakao: any;

@Options({
  name: "ViewEnsemble",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonNote,
    IonLabel,
    IonIcon,
    IonAvatar,
    IonButton,
    Player,
  },
})
export default class ViewEnsemble extends Vue {
  api = api;
  ionRouter = useIonRouter();
  play = play;
  heart = heart;
  close = close;
  heartOutline = heartOutline;
  createOutline = createOutline;
  shareSocial = shareSocial;
  ensembleId!: number;
  includePrivate!: boolean;
  isDirect!: boolean;
  ensemble: Recording[] = [];
  videos: any[] = [];
  videoReady = false;
  isPlaying = false;
  isLike = false;
  isEdit = false;
  editRecordingId = 0;
  playCmd = "";
  backable = false;
  isShare = false;
  isLandscape = false;

  created() {
    console.log(this.$route.params);
    if (this.$route.params && this.$route.params["ensembleId"]) {
      this.ensembleId = Number(this.$route.params["ensembleId"]);
    }
    if (this.$route.params && this.$route.params["includePrivate"]) {
      this.includePrivate = this.$route.params["includePrivate"] === "true" ? true : false;
    }
    if (this.$route.params && this.$route.params["isDirect"]) {
      this.isDirect = this.$route.params["isDirect"] === "true" ? true : false;
    }
  }

  mounted() {
    let params: { [id: string]: any } = {
      includePrivate: this.includePrivate,
    };
    // if (api.user.instruments) {
    //   params["joinInstruments"] = api.user.instruments;
    // }
    api.get(`/apps/recordings/${this.ensembleId}`, params).then((r) => {
      if (r && r.data) {
        this.ensemble = [r.data["recording"]];
        for (const u of this.ensemble[0].users) {
          if (u.userId === api.user.userId) {
            this.isEdit = true;
            this.editRecordingId = u.recordingId;
          }
        }
        const params = {
          userId: api.user.userId,
          recordingId: this.ensemble[0].users[0].recordingId,
        };
        api.get("/apps/check_like", params).then((r) => {
          if (r && r.data && r.data["status"] == "exist") {
            this.isLike = true;
          } else {
            this.isLike = false;
          }
        });
      }
    });
    this.backable = this.ionRouter.canGoBack();
  }

  ionViewDidEnter() {
    // App.addListener("appStateChange", (state: any) => {
    //   if (state.isActive == false) {
    //     this.playCmd = "pause";
    //   } else {
    //     this.playCmd = "";
    //   }
    // });
    console.log("viewEnsemble - will enter");
    const platforms = getPlatforms();
    if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
      console.log("load");
      this.playCmd = "load";
    }
    if (this.isIPad()) {
      ScreenOrientation.orientation().then((r: ScreenOrientationResult) => {
        if (r.type === "landscape-primary" || r.type === "landscape-secondary") {
          this.isLandscape = true;
        } else if (r.type === "portrait-primary" || r.type === "portrait-secondary") {
          this.isLandscape = false;
        }
        console.log("orientation", r.type, this.isLandscape);
      });
      setTimeout(() => {
        ScreenOrientation.addListener("screenOrientationChange", (r: ScreenOrientationResult) => {
          console.log("orientation", r.type);
          ScreenOrientation.orientation().then((r: ScreenOrientationResult) => {
            if (r.type === "landscape-primary" || r.type === "landscape-secondary") {
              this.isLandscape = true;
            } else if (r.type === "portrait-primary" || r.type === "portrait-secondary") {
              this.isLandscape = false;
            }
            console.log("orientation changed", r.type, this.isLandscape);
          });
        });
      }, 500);
    }
  }

  ionViewWillLeave() {
    console.log("viewEnsemble - will leave");
    if (this.isIPad()) {
      ScreenOrientation.removeAllListeners();
    }
    const platforms = getPlatforms();
    if (platforms.indexOf("ios") != -1 && platforms.indexOf("mobileweb") == -1) {
      console.log("close");
      this.playCmd = "close";
    } else {
      for (const v of this.videos) {
        v.dispose();
      }
    }
    // App.removeAllListeners();
  }

  onJoin() {
    this.playCmd = "pause";
    if (!api.user.userId) {
      this.ionRouter.push("/login");
      return;
    }
    if (this.ensemble.length > 0) {
      const e = this.ensemble[0];
      this.ionRouter.push(`/start/instrument/${e.productId}/${e.ensembleId}/${e.title}/${e.artist}/true`);
    }
  }

  onLike() {
    let isLikeDone = false;
    // if (this.ensemble.length > 0 && this.ensemble[0].isCompleted == "Y") {
    for (const u of this.ensemble[0].users) {
      const params = {
        userId: api.user.userId,
        recordingId: u.recordingId,
        isLike: !this.isLike,
      };
      api.post("/apps/recording_like", null, params).then((r) => {
        if (r && r.data) {
          if (!isLikeDone) {
            this.isLike = !this.isLike;
            isLikeDone = true;
          }
          if (this.isLike) {
            this.ensemble[0].likes += 1;
          } else {
            this.ensemble[0].likes -= 1;
          }
        }
      });
    }
    // }
  }

  async onEdit() {
    if (this.ensemble.length == 0) {
      return;
    }
    const actionSheet = await actionSheetController.create({
      cssClass: "custom-action-sheet",
      header: "Private settings",
      subHeader: "It will share my performance with my friends. or it will listen alone.",
      buttons: [
        {
          text: "Public",
          role: "destructive",
          data: {
            action: "public",
          },
        },
        {
          text: "Private",
          role: "destructive",
          data: {
            action: "private",
          },
        },
        {
          text: "Delete",
          role: "destructive",
          data: {
            action: "delete",
          },
        },
        {
          text: "Cancel",
          role: "cancel",
          data: {
            action: "cancel",
          },
        },
      ],
      backdropDismiss: false,
    });

    await actionSheet.present();

    const res = await actionSheet.onDidDismiss();
    console.log(res);
    if (res && res["data"] && res["data"]["action"]) {
      switch (res["data"]["action"]) {
        case "public": {
          const data = { status: "Y" };
          api.put(`/recordings/${this.editRecordingId}`, data).then((r) => {
            if (r && r.data) {
              api.needUpdate = true;
              console.log("public done");
              this.toast("Your settings has been saved.");
            }
          });
          break;
        }
        case "private": {
          const data = { status: "N" };
          api.put(`/recordings/${this.editRecordingId}`, data).then((r) => {
            if (r && r.data) {
              api.needUpdate = true;
              console.log("private done");
              this.toast("Your settings has been saved.");
            }
          });
          break;
        }
        case "delete": {
          this.confirmDelete();
        }
      }
    }
  }

  async onCopy() {
    const link = "solchestra:/" + window.location.pathname;
    const url = `https://api.solchestra.com/share?url=${link}`;

    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.value = url;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    this.toast("Link copied.");
    this.isShare = false;
  }

  onTwitter() {
    const link = "solchestra:/" + window.location.pathname;
    const url = `https://api.solchestra.com/share?url=${link}`;
    const txt = `[SOLCHESTA] ${this.ensemble[0].title} - ${this.ensemble[0].artist}`;
    window.open(`https://twitter.com/intent/tweet?text=${txt}&url=${url}`);
    this.isShare = false;
  }

  onFacebook() {
    const link = "solchestra:/" + window.location.pathname;
    const url = `https://api.solchestra.com/share?url=${link}`;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
    this.isShare = false;
  }

  onKakao() {
    const link = "solchestra:/" + window.location.pathname;
    const url = `https://api.solchestra.com/share?url=${link}`;
    const imageUrl = window.location.origin + api.publicPath + "/assets/icon/icon.png";
    const description = `${this.ensemble[0].title} - ${this.ensemble[0].artist}`;
    api.shareKakao(description, imageUrl, url);
    // const data = {
    //   objectType: "feed",
    //   content: {
    //     title: "SOLCHESTRA",
    //     description: `${this.ensemble[0].title} - ${this.ensemble[0].artist}`,
    //     imageUrl: imageUrl,
    //     link: {
    //       mobileWebUrl: url,
    //       webUrl: url,
    //     },
    //   },
    // };
    // console.log(data);
    // Kakao.Link.sendDefault(data);
    this.isShare = false;
  }

  async onShare() {
    this.isShare = true;
  }

  onShareClose() {
    this.isShare = false;
  }

  async confirmDelete() {
    const alert = await alertController.create({
      cssClass: "custom-alert custom-alert-pos",
      header: "Delete",
      message: "Are you sure you want to delete the recording?<br/>Deleted recording cannot be restored, and recording joined by other players are also deleted",
      buttons: [
        {
          text: "Delete",
          role: "delete",
          cssClass: "alert-button-default",
        },
        {
          text: "Cancel",
          role: "continue",
          cssClass: "alert-button-confirm",
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    if (role === "delete") {
      api.delete(`/recordings/${this.editRecordingId}`).then((r) => {
        if (r && r.data) {
          console.log("deleted");
          this.toast("The recording has been deleted.");
          api.needUpdate = true;
          this.$router.go(this.isDirect ? -1 : -2);
        }
      });
    }
    console.log(role);
  }

  async toast(msg: string) {
    const toast = await toastController.create({
      message: msg,
      duration: 1500,
      position: "bottom",
    });

    await toast.present();
  }

  onCloseView() {
    this.ionRouter.replace("/tabs/play");
  }

  goToUser(userId: number) {
    if (userId === api.user.userId) {
      this.ionRouter.replace(`/tabs/my`);
    } else {
      this.ionRouter.push(`/user/${userId}`);
    }
  }

  isIPad() {
    const platforms = getPlatforms();
    console.log("platforms", platforms);
    return platforms.indexOf("ipad") != -1;
  }
}
