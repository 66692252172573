
import { IonList, IonItem, IonLabel, IonNote, IonIcon, IonButton, IonInfiniteScroll, IonInfiniteScrollContent, InfiniteScrollCustomEvent, IonAvatar, IonThumbnail, useIonRouter } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api, StartedRecording } from "@/api/api";
import { play, heart, personCircle } from "ionicons/icons";
import { Prop, Watch } from "vue-property-decorator";

@Options({
  name: "StartedRecordings",
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonNote,
    IonIcon,
    IonButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonAvatar,
    IonThumbnail,
  },
})
export default class StartedRecordings extends Vue {
  @Prop({ type: Number })
  public titleId!: number;

  @Prop({ type: Number })
  public artistId!: number;

  @Prop({ type: Number })
  public user!: number;

  @Prop({ type: Boolean })
  public includePrivate!: boolean;

  @Prop({ type: String })
  public joinInstruments!: string;

  @Prop({ type: Boolean })
  public isJoin!: boolean;

  @Prop({ type: Number })
  public updateCnt!: number;

  @Prop({ type: Boolean })
  public isPush!: boolean;

  @Prop({ type: String })
  public title!: string;

  @Prop({ type: String })
  public artist!: string;

  ionRouter = useIonRouter();
  api = api;
  play = play;
  heart = heart;
  person = personCircle;

  recordings: StartedRecording[] = [];
  limit = 25;
  offset = 0;

  mounted() {
    this.load();
  }

  @Watch("isJoin")
  onIsJoinChanged() {
    this.load();
  }

  @Watch("updateCnt")
  onUpdateCntChanged() {
    this.load();
  }

  load(isNew = true) {
    if (isNew) {
      this.limit = 25;
      this.offset = 0;
    }
    const params: { [id: string]: any } = {
      limit: this.limit,
      offset: this.offset,
    };
    if (this.titleId) {
      params["titleId"] = this.titleId;
    }
    if (this.artistId) {
      params["artistId"] = this.artistId;
    }
    if (this.user) {
      params["user"] = this.user;
    }
    if (this.includePrivate) {
      params["includePrivate"] = this.includePrivate;
    }
    if (this.titleId) {
      params["titleId"] = this.titleId;
    }
    if (this.artistId) {
      params["artistId"] = this.artistId;
    }
    if (this.joinInstruments) {
      params["joinInstruments"] = this.joinInstruments;
    }
    api.get(`/apps/${this.isJoin ? "joined" : "started"}`, params).then((r) => {
      if (r && r.data) {
        if (isNew) {
          this.recordings = r.data["recordings"];
          this.offset += this.limit;
        } else {
          this.recordings = [...this.recordings, ...r.data["recordings"]];
          this.offset += this.limit;
        }
      }
    });
  }

  onInfiniteScroll(ev: InfiniteScrollCustomEvent) {
    this.load(false);
    setTimeout(() => ev.target.complete(), 500);
  }

  getInstrumentsString(s: string) {
    const insts = s.split(",").map((item) => item.trim());
    return insts.join(" + ");
  }

  // onStart(productId: number, title: string, artist: string) {
  //   if (!api.user.userId) {
  //     this.ionRouter.push("/login");
  //     return;
  //   }
  //   if (this.isPush) {
  //     this.ionRouter.push(`/start/instrument/${productId}/0/${encodeURIComponent(title)}/${encodeURIComponent(artist)}/true`);
  //   } else {
  //     this.ionRouter.replace(`/start/instrument/${productId}/0/${encodeURIComponent(title)}/${encodeURIComponent(artist)}/true`);
  //   }
  // }

  onJoin(productId: number, ensembleId: number, title: string, artist: string) {
    if (!api.user.userId) {
      this.ionRouter.push("/login");
      return;
    }
    // if (this.isPush) {
    this.ionRouter.push(`/start/instrument/${productId}/${ensembleId}/${encodeURIComponent(title)}/${encodeURIComponent(artist)}/true`);
    // } else {
    //   this.ionRouter.replace(`/start/instrument/${productId}/${ensembleId}/${encodeURIComponent(title)}/${encodeURIComponent(artist)}/true`);
    // }
  }

  onDetail(recordingId: number, title: string, artist: string) {
    // if (this.isPush) {
    this.ionRouter.push(`/startedDetail/${recordingId}/${this.includePrivate}/${encodeURIComponent(title)}/${encodeURIComponent(artist)}`);
    // } else {
    //   this.ionRouter.replace(`/startedDetail/${recordingId}/${this.includePrivate}/${encodeURIComponent(title)}/${encodeURIComponent(artist)}`);
    // }
  }

  goToUser(userId: number) {
    if (userId === api.user.userId) {
      // if (this.isPush) {
      //   this.ionRouter.push(`/tabs/my`);
      // } else {
      this.ionRouter.replace(`/tabs/my`);
      // }
    } else {
      // if (this.isPush) {
      this.ionRouter.push(`/user/${userId}`);
      // } else {
      //   this.ionRouter.replace(`/user/${userId}`);
      // }
    }
  }

  onView(ensembleId: number) {
    // if (this.isPush) {
    this.ionRouter.push(`/ensembles/${ensembleId}/${this.includePrivate}/true`);
    // } else {
    //   this.ionRouter.replace(`/ensembles/${ensembleId}/${this.includePrivate}`);
    // }
  }

  // onBeFirst() {
  //   if (!api.user.userId) {
  //     this.ionRouter.push("/login");
  //     return;
  //   }
  //   if (this.isPush) {
  //     this.ionRouter.push(`/start/ensemble/${this.titleId}/${this.artistId}/${this.title}/${this.artist}`);
  //   } else {
  //     this.ionRouter.replace(`/start/ensemble/${this.titleId}/${this.artistId}/${this.title}/${this.artist}`);
  //   }
  // }
}
