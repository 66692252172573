import { registerPlugin } from '@capacitor/core';

import type { SoluiPlugin } from './definitions';

const Solui = registerPlugin<SoluiPlugin>('Solui', {
  web: () => import('./web').then(m => new m.SoluiWeb()),
});

export * from './definitions';
export { Solui };
