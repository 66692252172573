
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonButton, IonIcon, IonList, IonItem, IonInput, IonSelect, IonSelectOption, IonLabel, useIonRouter, alertController, getPlatforms, toastController } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";

@Options({
  name: "Setting",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonIcon,
    IonList,
    IonItem,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonLabel,
  },
})
export default class Setting extends Vue {
  api = api;
  ionRouter = useIonRouter();
  instrument: string | null = null;
  genre: string[] | null = null;
  passwd = "";
  repasswd = "";
  genres: { [id: string]: string } = {};
  version = "1.0 (1)";

  count = 0;
  isDebug = false;

  mounted(): void {
    console.log("setting");
    api.get("/genres").then((r) => {
      if (r && r.data) {
        const genres = r.data["genres"];
        for (const g of genres) {
          console.log(g, g.genreMainId, g.name);
          this.genres[g.genreMainId] = g.name;
        }
      }
    });
    api.get(`/apps/user/${api.user.userId}`).then((r) => {
      if (r && r.data) {
        console.log(r.data);
        if (r.data["instrument"]) {
          this.instrument = r.data["instrument"];
        } else {
          this.instrument = "none";
        }
        if (r.data["preferedGenre"]) {
          this.genre = r.data["preferedGenre"].split(",");
        }
        console.log(this.genre);
      }
    });
    const platforms = getPlatforms();
    if ((platforms.indexOf("android") != -1 || platforms.indexOf("ios") != -1) && platforms.indexOf("mobileweb") == -1) {
      api.getVersion().then((v: string) => {
        this.version = v;
      });
    }
  }

  ionViewWillEnter() {
    this.count = 0;
  }

  onChangeInstrument(value: any) {
    if (this.instrument === value) {
      return;
    }
    const data: { [id: string]: string } = {
      instruments: value,
    };
    api.put(`/apps/user/${api.user.userId}`, data).then((r) => {
      console.log(r.data);
      this.instrument = value;
      if (value == "none") {
        api.user.instruments = null;
      }
      this.alert("Instrument(s) saved!");
    });
  }

  onChangeGenre(value: any) {
    if (JSON.stringify(this.genre) === JSON.stringify(value)) {
      return;
    }
    this.genre = value;
  }

  onDismissedGenre() {
    const data: { [id: string]: string } = {
      genres: this.genre ? this.genre.join(",") : "",
    };
    api.put(`/apps/user/${api.user.userId}`, data).then((r) => {
      console.log(r.data);
      this.alert("Genre saved!");
    });
  }

  async alert(msg: string) {
    const alert = await alertController.create({
      cssClass: "custom-alert",
      header: "Alert",
      message: msg,
      buttons: [
        {
          text: "OK",
          cssClass: "alert-button-confirm",
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
  }

  isValidPassword(pw: string): boolean {
    const rules = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$");
    return rules.test(pw);
  }

  async onChangePassword() {
    if (!this.isValidPassword(this.passwd)) {
      this.alert("At least 8 characters long.<br/>A combination of letters, numbers, and symbols.");
      return;
    }
    if (this.passwd !== this.repasswd) {
      this.alert("Confirm your password!");
      return;
    }

    const data: { [id: string]: string } = {
      password: this.passwd,
    };
    api.put(`/apps/user/${api.user.userId}`, data).then((r) => {
      if (r && r.data) {
        this.alert("Password changed!");
      }
    });
  }

  onNotice() {
    this.ionRouter.push("/notice");
  }

  onReport() {
    this.ionRouter.push("/report");
  }

  onRegal() {
    this.ionRouter.push("/legal");
  }

  onCopyright() {
    this.ionRouter.push("/copyright");
  }

  onLogout() {
    api.resetAuth();
    this.ionRouter.push("/tabs/play");
  }

  async onDeletion() {
    const alert = await alertController.create({
      cssClass: "custom-alert",
      header: "Alert",
      message: "Are you sure you want to delete your account? If you delete your account, all data will be deleted.",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "alert-button-confirm",
        },
        {
          text: "Delete",
          role: "delete",
          cssClass: "alert-button-default",
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === "delete") {
      api.delete(`/users/${api.user.userId}`).then((r) => {
        if (r && r.data) {
          this.toast("Your account and all data has been deleted.");
          api.resetAuth();
          this.ionRouter.push("/tabs/play");
        }
      })
      
    } 
  }

  onVersionClick() {
    this.count += 1;
    if (this.count >= 10) {
      this.runDebug();
    }
  }

  runDebug() {
    if (!this.isDebug) {
      document.body.insertAdjacentHTML("beforeend", '<div id="debugDiv" style="position: absolute; width: 100%; height: 100%; pointer-events: none; overflow-x:scroll"> <div id="debugMessage"></div> </div>');
      (window as any).enableOLog();
      this.toast("debug enabled");
      this.isDebug = true;
    }
  }

  async toast(msg: string) {
    const toast = await toastController.create({
      message: msg,
      duration: 1500,
      position: "bottom",
    });

    await toast.present();
  }
}
