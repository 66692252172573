
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonButton, IonList, IonItem, IonLabel, useIonRouter } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";

@Options({
  name: "Legal",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
  },
})
export default class Legal extends Vue {
  api = api;
  ionRouter = useIonRouter();

  count = 0;
  isDebug = false;

  ionViewWillEnter() {
    this.count = 0;
  }

  onClick() {
    this.count += 1;
    if (this.count >= 10) {
      if (window.location.href.indexOf("belokan") == -1) {
        window.location.replace("https://belokan.net/app");
      }
    }
  }
}
