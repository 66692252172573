
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, useIonRouter, IonLabel, IonItem, IonNote, IonButton, IonFooter, IonIcon } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";

@Options({
  name: "SelectInstrument",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonLabel,
    IonItem,
    IonNote,
    IonButton,
    IonFooter,
    IonIcon,
  },
})
export default class SelectInstrument extends Vue {
  api = api;
  ionRouter = useIonRouter();
  productId: number | null = null;
  ensembleId = 0;
  title: string | null = null;
  artist: string | null = null;
  isRecording: boolean | null = null;
  parts: string[] = [];
  occupied: number[] = [];
  started = -1;
  selected = -1;
  isShowPlay = true;

  created() {
    if (this.$route.params && this.$route.params["productId"]) {
      this.productId = Number(this.$route.params["productId"]);
    }
    if (this.$route.params && this.$route.params["ensembleId"]) {
      this.ensembleId = Number(this.$route.params["ensembleId"]);
    }
    if (this.$route.params && this.$route.params["title"]) {
      this.title = String(this.$route.params["title"]);
    }
    if (this.$route.params && this.$route.params["artist"]) {
      this.artist = String(this.$route.params["artist"]);
    }
    if (this.$route.params && this.$route.params["isRecording"]) {
      this.isRecording = this.$route.params["isRecording"] === "true" ? true : false;
    }
    console.log(this.ensembleId);
  }

  mounted(): void {
    api.get(`/products/${this.productId}/parts`).then((r) => {
      if (r && r.data) {
        this.occupied = [];
        if (this.ensembleId) {
          api.get(`/apps/recordings/${this.ensembleId}/parts`).then((er) => {
            if (er && er.data) {
              this.occupied = er.data["parts"].map((e: { [x: string]: any }) => Number(e["no"]) - 1);
              this.started = Number(er.data["parts"].find((e: { [x: string]: any }) => e["start"] == "Y")["no"]) - 1;
              this.selectPreferedInstrument(r.data, this.started);
            } else {
              this.selectPreferedInstrument(r.data);
            }
          });
        } else {
          this.selectPreferedInstrument(r.data);
        }
      }
    });
  }

  ionViewWillLeave() {
    this.isShowPlay = false;
  }

  ionViewWillEnter() {
    this.isShowPlay = true;
  }

  selectPreferedInstrument(songParts: { [x: string]: any }, started = -1) {
    this.parts = [];
    this.selected = -1;
    for (let i = 0; i < songParts["parts"].length; i++) {
      const part = songParts["parts"][i];
      const inst = part["instrument_code"];
      if (this.selected == -1 && api.user.instruments && i != started) {
        const insts = api.user.instruments?.split(",");
        if (insts.indexOf(inst) != -1) {
          this.selected = i;
        }
      }
      this.parts.push(inst);
    }
    console.log(this.parts, this.occupied, this.selected);
  }

  onSelect(i: number) {
    console.log(i, this.occupied, this.selected);
    if (this.started == i) {
      return;
    }
    if (this.selected == -1) {
      this.selected = i;
    } else {
      this.selected = -1;
    }
    console.log(i, this.selected);
  }

  onPlay(): void {
    if (this.selected == -1) {
      return;
    }
    this.ionRouter.push(`/start/record/${this.productId}/${this.ensembleId}/${this.selected + 1}/${this.parts[this.selected]}/${this.title}/${this.artist}/${this.isRecording}`);
  }

  getNth(inst: string, index: number, parts: string[]): string {
    const cnt = parts.reduce((a, c) => (c === inst ? a + 1 : a), 0);
    if (cnt > 1) {
      let nth = 1;
      for (let i = 0; i < index; i++) {
        if (parts[i] === inst) {
          nth += 1;
        }
      }
      return `${nth}`;
    }
    return "";
  }
}
