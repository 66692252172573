import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue, getPlatforms } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import { ScreenOrientation } from "@capacitor/screen-orientation";

/* Theme variables */
import "./theme/variables.css";

import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';

const app = createApp(App).use(IonicVue, { mode: 'ios'}).use(router);

router.isReady().then(() => {
  app.mount("#app");
  const platforms = getPlatforms();
  if (platforms.indexOf("ipad") == -1) {
    ScreenOrientation.lock({ orientation: "portrait" });
  }
});

CapacitorApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
  const slug = event.url.split('solchestra://app').pop();
  console.log(event.url, slug);
  if (slug) {
    router.push({
      path: slug,
    });
  }
});
