
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonButton, IonList, IonItem, IonLabel, IonTextarea, useIonRouter, alertController } from "@ionic/vue";
import { Options, Vue } from "vue-class-component";
import { api } from "@/api/api";

@Options({
  name: "Report",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    IonTextarea,
  },
})
export default class Report extends Vue {
  api = api;
  ionRouter = useIonRouter();
  text = "";

  onViewNotice(articleId: number) {
    this.ionRouter.push(`/notice/${articleId}`);
  }

  onReport() {
    const data = {
      boardId: 3,
      status: "Y",
      title: "Bug Report",
      content: this.text,
    };
    api.post("/articles", data).then((r) => {
      if (r && r.data) {
        this.alert("Thanky you for your reporting");
      }
    });
  }

  async alert(msg: string) {
    const alert = await alertController.create({
      cssClass: "custom-alert",
      header: "Alert",
      message: msg,
      buttons: [
        {
          text: "OK",
          cssClass: "alert-button-confirm",
        },
      ],
      backdropDismiss: false,
    });
    await alert.present();
    await alert.onDidDismiss();
    this.$router.back();
  }
}
